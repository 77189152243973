import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { transformToCurrency } from '../../../../../lib/utils';
import { Text } from '../../../../TextComponents';
import { Tag } from '../../../TagsAndStatus';

type DeductibleTagProps = {
	deductible: number;
	className?: string;
};

export const DeductibleTag: FC<DeductibleTagProps> = ({ deductible, className = '' }) => {
	const { t } = useTranslation(['common']);
	return (
		<Tag
			className={className}
			text={
				<div className='flex flex-row items-center space-x-4'>
					<Text as='span' variant='body-m' weight='semibold' color='grayscale600'>
						{transformToCurrency(deductible)}
					</Text>
					<Text as='span' variant='body-xs' color='grayscale600'>
						{t('common.ownRisk')}
					</Text>
				</div>
			}
		/>
	);
};
