import { faker } from '@faker-js/faker/locale/nl';

import { Cart, CreatedCartResponse, Insurance } from '@monorepo/types';

import { mockGetConvertedInsurance } from './convertedInsurance.mock';

export function mockGetPremiumQuestionReferences(): string[] {
	return ['revenue', 'fte'];
}

export function mockGetGetAcceptanceQuestionReferences(): string[] {
	return [
		'current-insurance',
		'cancel-current-insurance',
		'acceptance-statement',
		'cyber-acceptance-statement',
		'historical-claims',
		'claims-overview',
		'closing-statement',
	];
}

export function mockGetInsurance(guid: string): Insurance {
	return {
		guid,
		code: 'nvm',
		minimum_effective_date: `${faker.date.anytime()}`,
		converted: false,
		billing_unit: 'monthly',
		insurance: mockGetConvertedInsurance(),
		contact_type: '',
	};
}

export function mockGetCart(guid: string): Cart {
	return {
		guid,
		is_expired: false,
		code: 'nvm',
		minimum_effective_date: `${faker.date.anytime()}`,
		billing_unit: 'monthly',
		converted: false,
		insurance: mockGetConvertedInsurance(),
		calculation_types: {
			products: true,
			bundles: false,
			compositions: false,
		},
		contact_type: '',
	};
}

export function mockGetCreatedCartResponse(): CreatedCartResponse {
	return {
		cart_guid: faker.string.alphanumeric(16),
		existing_customer: true,
	};
}
