import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'next-i18next';
import { FC, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { Form } from '@monorepo/components/DataEntry';
import { cancelCurrentInsuranceSchema, CancelCurrentInsuranceSchema } from '@monorepo/shared/lib/schemas';
import { toReactSelectObject } from '@monorepo/shared/lib/utils';
import { InsuranceToCancel } from '@monorepo/types';

import { useGetInsurersQuery, useGetTransferableProductsQuery } from '@funnel/store';

type InsuranceFormProps = {
	id?: string;
	cartGuid: string;
	defaultValues?: InsuranceToCancel;
	isLoading?: boolean;
	onSubmit: (insurance: InsuranceToCancel) => void;
	onChangeIsFormValid?: (isValid: boolean) => void;
};

export const InsuranceForm: FC<InsuranceFormProps> = ({
	id,
	cartGuid,
	defaultValues,
	onSubmit,
	onChangeIsFormValid,
}) => {
	const { t } = useTranslation(['common']);

	const { data: transferableProducts = [], isLoading: tpIsLoading } = useGetTransferableProductsQuery(cartGuid);
	const { data: insurers = [], isLoading: inIsLoading } = useGetInsurersQuery();

	const insurersDropdownOptions = useMemo(() => toReactSelectObject(insurers), [insurers]);
	const insurancesDropdownOptions = useMemo(() => toReactSelectObject(transferableProducts), [transferableProducts]);
	const hasOneProduct = transferableProducts?.length === 1;

	const cancelCurrentInsuranceForm = useForm<CancelCurrentInsuranceSchema>({
		mode: 'onChange',
		resolver: zodResolver(cancelCurrentInsuranceSchema(t)),
		defaultValues,
	});

	useEffect(() => {
		onChangeIsFormValid && onChangeIsFormValid(cancelCurrentInsuranceForm.formState.isValid);
	}, [cancelCurrentInsuranceForm.formState.isValid, onChangeIsFormValid]);

	useEffect(() => {
		if (hasOneProduct) {
			cancelCurrentInsuranceForm.setValue('product_guid', `${transferableProducts[0].guid}`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasOneProduct]);

	return (
		<Form
			id={id}
			className='grid grid-cols-12 gap-x-6 gap-y-32'
			onSubmit={cancelCurrentInsuranceForm.handleSubmit(onSubmit)}>
			{defaultValues?.guid && (
				<Form.Group className='hidden'>
					<Form.HiddenInput name='guid' className='hidden' register={cancelCurrentInsuranceForm.register} />
				</Form.Group>
			)}

			<Form.Group className='col-span-12'>
				<Form.Label htmlFor='product_guid'>
					{t('page.funnel.preconditions.cancelCurrentInsurance.form.insuranceLabel')}
				</Form.Label>
				<Form.Dropdown
					fieldSize='lg'
					name='product_guid'
					isLoading={tpIsLoading}
					disabled={hasOneProduct || tpIsLoading}
					control={cancelCurrentInsuranceForm.control}
					placeholder={t('page.funnel.preconditions.cancelCurrentInsurance.form.insurancePlaceholder')}
					className='z-40'
					errors={cancelCurrentInsuranceForm.formState.errors.product_guid}
					options={insurancesDropdownOptions}
				/>
			</Form.Group>
			<Form.Group className='col-span-12'>
				<Form.Label htmlFor='insurer_code'>
					{t('page.funnel.preconditions.cancelCurrentInsurance.form.insurerLabel')}
				</Form.Label>
				<Form.Dropdown
					fieldSize='lg'
					name='insurer_code'
					isLoading={inIsLoading}
					disabled={inIsLoading}
					createable={true}
					control={cancelCurrentInsuranceForm.control}
					placeholder={t('page.funnel.preconditions.cancelCurrentInsurance.form.insurerPlaceholder')}
					errors={cancelCurrentInsuranceForm.formState.errors.insurer_code}
					options={insurersDropdownOptions}
				/>
			</Form.Group>
			<Form.Group className='col-span-12'>
				<Form.Label htmlFor='policy_identifier'>
					{t('page.funnel.preconditions.cancelCurrentInsurance.form.policyNumberLabel')}
				</Form.Label>
				<Form.TextInput
					fieldSize='lg'
					name='policy_identifier'
					placeholder={t('page.funnel.preconditions.cancelCurrentInsurance.form.policyNumberPlaceholder')}
					errors={cancelCurrentInsuranceForm.formState.errors.policy_identifier}
					register={cancelCurrentInsuranceForm.register}
				/>
			</Form.Group>
		</Form>
	);
};
