import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import type { FC } from 'react';

import type { Address, Company } from '@monorepo/types';

import { generateSizesString } from '../../../../lib/utils';
import { IconButton, Skeleton } from '../../../Misc';
import { Text } from '../../../TextComponents';
import { BaseCard } from '../BaseCard/BaseCard';
import { DataEditCard } from '../DataEditCard/DataEditCard';

type Props = {
	company: Company;
	hideThumbnail?: boolean;
	imageSrc?: string;
	className?: string;
	isLoading?: boolean;
	onEditClick?: () => void;
	onDeleteClick?: () => void;
};

export const LocationCard: FC<Props> = ({
	company,
	imageSrc,
	className,
	onEditClick,
	onDeleteClick,
	hideThumbnail = false,
	isLoading = false,
}) => {
	const { t } = useTranslation(['common']);

	return (
		<>
			<BaseCard className={cn(className, 'pointer-events-none flex-col', isLoading && 'border-grayscale100')}>
				{!hideThumbnail && (
					<Skeleton isLoading={isLoading} variant='rectangular'>
						<div className='rounded-t-18 relative h-[160px] overflow-hidden md:h-[200px]'>
							{imageSrc && (
								<Image
									fill
									unoptimized // TODO: Remove this when image optimziation is fixed in Vercel
									className='object-cover'
									quality={100}
									sizes={generateSizesString()}
									priority
									src={`${imageSrc}`}
									alt={`${company.address?.street}, ${company.address?.postal_code}, ${company.address?.city}`}
								/>
							)}
						</div>
					</Skeleton>
				)}

				<div className='space-y-16 p-20 md:p-24'>
					<div className='flex flex-row'>
						<div className='flex-1 space-y-4'>
							<Skeleton isLoading={isLoading} variant='text' size='body-s' className='w-[125px]'>
								<Text variant='body-s' color='grayscale400' data-testid='location-card-coc'>
									{t('components.companyLocation.coc', { dossierNumber: company.dossierNumber })}
								</Text>
							</Skeleton>

							<Skeleton isLoading={isLoading} variant='text' size='display-6' className='w-[275px]'>
								<Text
									variant='display-6'
									color='grayscale600'
									weight='semibold'
									data-testid='location-card-company-name'>
									{company.name}
								</Text>
							</Skeleton>
						</div>

						{onDeleteClick && (
							<div>
								<IconButton
									name={t('common.delete')}
									title={t('common.delete')}
									className='pointer-events-auto'
									iconName='trash-alt'
									color='info300'
									onClick={() => onDeleteClick()}
								/>
							</div>
						)}
					</div>
					<DataEditCard
						isLoading={isLoading}
						address={company.address as Address}
						className='w-full'
						onEditClick={onEditClick}
					/>
				</div>
			</BaseCard>
		</>
	);
};
