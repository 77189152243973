import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useDebounce } from 'react-use';

import { Form } from '@monorepo/components/DataEntry';
import { searchCompanySchema, SearchCompanySchema } from '@monorepo/shared/lib/schemas';

type CompanySearchProps = {
	placeholder: string;
	defaultValue?: string;
	onChangeQuery: SubmitHandler<SearchCompanySchema>;
};

export const CompanySearchForm: FC<CompanySearchProps> = ({ placeholder, defaultValue, onChangeQuery }) => {
	const { t } = useTranslation(['common']);

	const searchCompanyForm = useForm<SearchCompanySchema>({
		mode: 'onTouched',
		resolver: zodResolver(searchCompanySchema(t)),
		defaultValues: {
			query: defaultValue || undefined,
		},
	});

	const query = searchCompanyForm.watch('query');

	const [] = useDebounce(
		() => {
			onChangeQuery(searchCompanyForm.getValues());
		},
		450,
		[query]
	);

	return (
		<FormProvider {...searchCompanyForm}>
			<Form.Context<SearchCompanySchema>>
				{({ handleSubmit, register }) => {
					return (
						<Form id='company-search-form' onSubmit={handleSubmit(onChangeQuery)}>
							<Form.Group>
								<Form.SearchInput
									name='query'
									defaultValue={defaultValue}
									startIcon='search'
									autoComplete='off'
									placeholder={placeholder}
									register={register}
									autoFocus={!defaultValue}
								/>
							</Form.Group>
						</Form>
					);
				}}
			</Form.Context>
		</FormProvider>
	);
};
