import { z } from 'zod';

import { TranslationType } from '@monorepo/types';

import { D_DATE_FORMAT } from '../../utils';

export const objectDetailsSchema = (t: TranslationType) =>
	z.object({
		vehicle_code: z.string({ required_error: t('validation.required') }),
		address: z.object({
			postal_code: z.string({
				required_error: t('validation.required'),
			}),
			house_number: z.string({ required_error: t('validation.required') }),
			house_number_appendix: z.string().optional(),
			street: z.string({
				required_error: t('validation.required'),
			}),
			city: z.string({
				required_error: t('validation.required'),
			}),
		}),
		first_name: z
			.string({
				required_error: t('validation.required'),
			})
			.min(1, t('validation.required')),
		date_of_birth: z
			.string({ required_error: t('validation.required') })
			// Validate format and basic date validity
			.refine(
				(value) => {
					if (!value) return true; // Allow null/undefined values
					const parts = value.replaceAll(' ', '').split('-');
					if (parts.join('').length !== 8) return false;
					const rearrangedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
					const parsedDate = new Date(rearrangedDate);
					return !isNaN(parsedDate.getTime());
				},
				{ message: t('validation.formatSuggestion', { format: D_DATE_FORMAT.toLowerCase() }) }
			)
			// Validate date is not in the future
			.refine(
				(value) => {
					if (!value) return true;
					const parts = value.replaceAll(' ', '').split('-');
					const rearrangedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
					const parsedDate = new Date(rearrangedDate);
					const todayDate = new Date();
					todayDate.setHours(0, 0, 0, 0);
					return parsedDate <= todayDate;
				},
				{ message: t('validation.dateValidBirthday') }
			), // Validate age is 18 or above
		'km-year': z
			.string({
				required_error: t('validation.required'),
			})
			.optional(),
		'damage-free-years': z
			.string()
			.transform((value) => parseInt(value, 10))
			.refine((value) => !isNaN(value), {
				message: t('validation.number'),
			})
			.refine((value) => value >= -5, {
				message: t('validation.min', { min: -5 }),
			}),
		'driving-certificate': z.enum(['J', 'N'], {
			required_error: t('validation.required'),
		}),
	});

export type ObjectDetailsSchema = z.infer<ReturnType<typeof objectDetailsSchema>>;
