import cn from 'classnames';
import parse from 'html-react-parser';
import { FC, useState } from 'react';

import { QuestionToolTip } from '@monorepo/types';

import { parseStatement, slugify } from '../../../../lib/utils';
import { Toggle } from '../../../DataEntry';
import { IconItem } from '../../../TextComponents';
import { TooltipPopup } from '../../Tooltips';
import { BaseCard } from '../BaseCard/BaseCard';

type Props = {
	label: string;
	subtext?: string;
	tooltips?: QuestionToolTip[];
	isToggled: boolean;
	onToggleChange: (isToggled: boolean) => void;
	icon: string;
	className?: string;
};

export const StatementCard: FC<Props> = ({
	label,
	subtext,
	tooltips = [],
	isToggled,
	icon,
	onToggleChange,
	className,
}) => {
	const [internalIsToggled, setInternalIsToggled] = useState<boolean>(isToggled || false);

	const parsedLabel = parseStatement(label, tooltips);
	const parsedSubtext = typeof subtext === 'string' ? parseStatement(subtext) : subtext;

	const handleToggle = () => {
		setInternalIsToggled((currentValue) => !currentValue);
		onToggleChange(internalIsToggled);
	};

	return (
		<>
			{tooltips.map(({ string, tooltip }) => (
				<TooltipPopup
					key={`tooltip-container-${slugify(string)}`}
					id={`tooltip-${slugify(string)}`}
					content={parse(tooltip)}
				/>
			))}

			<BaseCard
				isElevated={internalIsToggled}
				className={cn(
					className,
					subtext ? 'px-16 py-12 md:px-24 md:py-16' : 'px-16 py-12',
					'pointer-events-none flex-row items-center justify-between space-x-12'
				)}
				data-testid='statement-card'>
				<IconItem
					isListItem={false}
					className='prose prose-p:m-0 prose-li:m-0 prose-li:p-0 prose-ul:mx-0 prose-ul:mb-0 prose-ul:mt-4 prose-ul:px-20 prose-ul:list-disc prose-p:text-grayscale600 prose-li:text-grayscale500 prose-li:marker:text-inherit grow items-center'
					item={{ icon, title: parsedLabel, subtext: parsedSubtext, titleWeight: subtext ? 'medium' : 'regular' }}
				/>

				<Toggle
					variant='onOff'
					className='maia-insurance-product-toggle pointer-events-auto'
					onChange={handleToggle}
					isToggled={internalIsToggled}
					data-testid='statement-card-toggle'
				/>
			</BaseCard>
		</>
	);
};
