import { FC } from 'react';

import { DeductibleTag, PriceSticker, PriceStickerSizes, TagProps } from '@monorepo/components/DataDisplay';
import { ProductIcon } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { findElementByIdentifier } from '@monorepo/shared/lib/cms';
import { BillingUnits, CmsElement } from '@monorepo/types';

import { mapContentToComponent } from '@cms/componentMapper/componentMapper';

type Props = {
	productName: string;
	productIcon: string;
	productIsActive: boolean;
	content?: CmsElement[];
	runningPromotion: string | boolean | null | undefined;
	price: number;
	priceSize?: PriceStickerSizes;
	deductible?: number;
	billingUnit?: BillingUnits;
};

export const ProductCardHeader: FC<Props> = ({
	productName,
	productIcon,
	productIsActive,
	content,
	runningPromotion,
	price,
	priceSize = 'small',
	billingUnit = 'monthly',
	deductible,
}) => {
	const taglineContent = findElementByIdentifier(content || [], 'product_tagline') || {};
	const Tagline = mapContentToComponent(taglineContent as CmsElement);
	const tagContent = findElementByIdentifier(content || [], 'tags_tag') || {};
	const Tag = mapContentToComponent<TagProps>(tagContent as CmsElement);

	return (
		<div className='flex flex-col space-y-12'>
			<div className='flex flex-col justify-between max-sm:space-y-16 sm:flex-row sm:space-x-12'>
				<div className='flex grow items-center space-x-16'>
					<ProductIcon name={productIcon} />
					<div className='space-y-4 overflow-auto'>
						<Text variant='display-6' weight='semibold' color='grayscale600' className='hyphens-auto'>
							{productName}
						</Text>

						{taglineContent && <Tagline />}
					</div>
				</div>
				<div className='flex flex-wrap items-stretch gap-12 sm:flex-col sm:items-end'>
					{runningPromotion === 'first_year' ? (
						<PriceSticker billingUnit={billingUnit} variant='free' size='large' price={price} />
					) : (
						<PriceSticker
							billingUnit={billingUnit}
							variant='default'
							size={priceSize}
							excludeFrom={productIsActive}
							price={price}
						/>
					)}

					{tagContent && <Tag />}

					{deductible && <DeductibleTag deductible={deductible} className='sm:hidden' />}
				</div>
			</div>
			{deductible && <DeductibleTag deductible={deductible} className='max-sm:hidden' />}
		</div>
	);
};
