import { z } from 'zod';

import { TranslationType } from '@monorepo/types';

import { D_DATE_FORMAT, PHONE_REGEX } from '../../utils';

export const contactConsumerSchema = (t: TranslationType) => {
	const minDate = new Date(new Date().getFullYear() - 150, new Date().getMonth(), new Date().getDay());

	return z.object({
		initials: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
		first_name: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
		last_name: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
		email: z.string({ required_error: t('validation.required') }).email(t('validation.email')),
		phone: z.string({ required_error: t('validation.required') }).regex(PHONE_REGEX, t('validation.phone')),
		date_of_birth: z.string({ required_error: t('validation.required') }).superRefine((value, ctx) => {
			if (!value) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: t('validation.required'),
				});
			}

			const parts = value.replaceAll(' ', '').split('-');

			if (parts.join('').length !== 8) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: t('validation.formatSuggestion', { format: D_DATE_FORMAT.toLowerCase() }),
				});
			}

			const rearrangedDate = parts[2] + '-' + parts[1] + '-' + parts[0];
			const parsedDate = new Date(rearrangedDate);

			const todayDate = new Date();
			todayDate.setHours(0, 0, 0, 0);

			if (isNaN(parsedDate.getTime())) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: t('validation.formatSuggestion', { format: D_DATE_FORMAT.toLowerCase() }),
				});
			}

			if (parsedDate >= todayDate) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: t('validation.dateValidBirthday'),
				});
			}

			if (parsedDate <= minDate) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: t('validation.dateValidBirthday'),
				});
			}
		}),
	});
};

export type ContactConsumerSchema = z.infer<ReturnType<typeof contactConsumerSchema>>;
