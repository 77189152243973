import cn from 'classnames';
import type { ClassNamesConfig, StylesConfig } from 'react-select';

import {
	affinityGroups,
	type ReactSelectOption,
	type Theme,
	type ThemeCssProps,
	type ThemeNames,
	type ThemeTypography,
	type ThemeTypographySizes,
} from '@monorepo/types';

import { flatAndSlugifyObj } from '../helpers/helpers';
import { themes } from './themeDefinitions';

export const SALES_CHANNEL_COOKIENAME = 'sales_channel';

/**
 * Set theme
 */
export function applyTheme(theme: ThemeCssProps): Promise<void> {
	return new Promise<void>((resolve, reject) => {
		try {
			const root: HTMLElement = document.documentElement;
			Object.keys(theme).forEach((cssVar: string) => {
				root.style.setProperty(cssVar, theme[cssVar]);
			});
			resolve();
		} catch (err) {
			reject();
		}
	});
}

/**
 * Create theme
 */
export function createTheme(theme: Theme): ThemeCssProps {
	const slugs = flatAndSlugifyObj<Theme>(theme);
	const cssProps: { [key: string]: string } = {};

	Object.entries(slugs).forEach(([key, value]) => {
		cssProps[`--${key}`] = value;
	});

	return cssProps;
}

/**
 * Returns a created theme
 */
export function getCreatedTheme(name: ThemeNames): ThemeCssProps {
	return createTheme(themes[name]);
}

export const affinityGroupLabelUrlMap: Record<ThemeNames, string> = {
	maia: 'https://www.maia.insure/',
	nkc: 'https://app.www.nkc.nl/',
	nvm: 'https://app.schoutenverzekeringsdienstnvm.nl/',
	vbo: 'https://app.schoutenzekerheid.nl/',
	vastgoedpro: 'https://app.vastgoedproverzekeringen.nl/',
	onderhoudnl: 'https://app.onderhoudnlverzekeringen.nl/',
	anko: 'https://app.ankoverzekeringsdienst.nl/',
	cybermeister: 'https://cybermeister.maia.insure/',
	meestersgilde: 'https://meestersgilde.maia.insure/',
	trustoo: 'https://trustoo.maia.insure/',
	deonlinebeautycursussen: 'https://deonlinebeautycursussen.maia.insure/',
	marengo: 'https://app.marengo.nl/',
};

export const getBaseUrlForAffinityGroup = (affinityGroup: ThemeNames): string => {
	if (affinityGroupLabelUrlMap[affinityGroup]) {
		return affinityGroupLabelUrlMap[affinityGroup];
	}

	return affinityGroupLabelUrlMap.maia;
};

export const affinityGroupToHostname: Record<string, ThemeNames> = Object.fromEntries(
	Object.entries(affinityGroupLabelUrlMap).map(([ag, domain]) => {
		try {
			const url = new URL(domain);
			return [url.hostname, ag as ThemeNames];
		} catch {
			return [domain, ag as ThemeNames];
		}
	})
);

export const getAffinityGroupfromSearchParams = (url: string): ThemeNames | null => {
	const _url = new URL(url);

	if (
		_url.searchParams.has('saleschannel') &&
		affinityGroups.includes(_url.searchParams.get('saleschannel') as ThemeNames)
	) {
		return _url.searchParams.get('saleschannel') as ThemeNames;
	}

	return null;
};

export const getAffinityGroupFromUrl = (url: string): ThemeNames => {
	const _url = new URL(url);

	if (_url.hostname in affinityGroupToHostname) {
		return affinityGroupToHostname[_url.hostname];
	}

	return 'maia';
};

/**
 * Flattens all typography variants
 */
export const flattenTypographySizes = (typography: ThemeTypographySizes) => {
	const flattenedObj: Record<string, string> = {};

	for (const key in typography) {
		if (typography.hasOwnProperty(key)) {
			const desktopKey = `${key}-desktop`;
			const mobileKey = `${key}-mobile`;
			flattenedObj[desktopKey] = typography[key as ThemeTypography].desktop;
			flattenedObj[mobileKey] = typography[key as ThemeTypography].mobile;
		}
	}

	return flattenedObj;
};
/**
 * Return styling for ReactSelect component
 * @deprecated use getReactSelectClassNames when implemented
 */
export function getReactSelectStyles(name: ThemeNames): StylesConfig<ReactSelectOption, false> {
	const theme = themes[name];
	return {
		control: (baseStyles, state) => ({
			...baseStyles,
			boxShadow: 'none',
			borderColor: state.isFocused ? theme.colors.primaryMain : theme.colors.grayscale200,
		}),
		placeholder: (baseStyles) => ({
			...baseStyles,
			color: theme.colors.grayscale300,
		}),
		option: (baseStyles, state) => ({
			...baseStyles,
			color: theme.colors.primaryMain,
			backgroundColor: state.isFocused ? theme.colors.primary100 : theme.colors.grayscale0,
		}),
	};
}

/**
 * Return styling for ReactSelect component for splashscreen
 */
export function getHomeReactSelectClassNames(): ClassNamesConfig<ReactSelectOption, false> {
	return {
		valueContainer: () => 'cursor-pointer',
		control: () => cn('bg-grayscale0 border cursor-pointer border-grayscale0 h-48 rounded-6'),
		input: () => 'text-grayscale500 py-8 px-12',
		singleValue: () => 'text-grayscale500 px-12',
		placeholder: () => 'text-grayscale500 px-12',
		dropdownIndicator: () => 'text-grayscale500 cursor-pointer',
		indicatorsContainer: () => 'pr-12 cursor-pointer',
		menu: () => cn('mt-8 shadow-1 rounded-12'),
		menuList: () => 'bg-grayscale0 border border-grayscale100 rounded-12',
		option: ({ isFocused }) =>
			cn(isFocused && 'bg-primary100', 'text-grayscale500 !h-48 cursor-pointer py-8 px-12 !cursor-pointer'),
	};
}

export function getFiltersClassNames(): ClassNamesConfig<ReactSelectOption, false> {
	return {
		container: () => 'h-full',
		valueContainer: () => 'cursor-pointer',
		control: () => cn('bg-shades200 rounded-sm cursor-pointer h-full'),
		input: () => 'text-shades400 py-2 px-3',
		singleValue: () => 'text-shades400 px-3',
		placeholder: () => 'text-shades400 px-3',
		dropdownIndicator: () => 'cursor-pointer text-shades400',
		indicatorsContainer: () => 'cursor-pointer pr-3',
		menu: () => cn('mt-2 cur'),
		menuList: () => 'bg-shades0 rounded-sm cursor-pointer',
		option: ({ isFocused }) => cn(isFocused ? 'text-shades600' : 'text-shades400', 'py-2 px-3'),
	};
}
