import { Trans, useTranslation } from 'next-i18next';
import { FC } from 'react';

import { BillingUnits, CartItem, ThemeNames } from '@monorepo/types';

import { transformToCurrency } from '../../../../lib/utils';
import { Icon } from '../../../Misc';
import { Text } from '../../../TextComponents';

type Props = CartItem & {
	billingUnit?: BillingUnits;
	theme: ThemeNames;
};

export const ShoppingCartItem: FC<Props> = ({
	name,
	items,
	price,
	promotion,
	priceBeforeDiscount,
	icon,
	billingUnit = 'monthly',
	theme,
}) => {
	const { t } = useTranslation(['common']);

	return (
		<div className='bg-grayscale0 z-10 flex flex-col space-y-8'>
			<div className='flex flex-row items-start space-x-8'>
				<Icon color='grayscale600' className='leading-none' name={icon} />
				<div className='flex w-full flex-col space-y-8'>
					<div className='flex w-full flex-row items-start'>
						<Text as='p' variant='body-s' weight='semibold' color='grayscale600' className='hyphens-auto'>
							{name}
						</Text>
					</div>

					{items
						.filter((item) => !!item?.descriptions && item?.descriptions.length > 0)
						.map(({ name: optionName, descriptions }, index) => (
							<div key={`shopping-cart-item-${index}`} className='space-y-4'>
								{/* Only show the name of 'dekking' if it differs from the productname  */}
								{optionName !== name &&
									name !== 'Rechtsbijstand voor Ondernemers' &&
									name !== 'Werknemersschade' &&
									name !== 'Werknemersschadeverzekering' && (
										<Text as='p' variant='body-xs' weight='medium'>
											{optionName}
										</Text>
									)}

								{(descriptions as string[]).map((text, index) => {
									return (
										<Text as='p' key={`subtext-${index}`} variant='body-xs' color='grayscale400'>
											{text}
										</Text>
									);
								})}
							</div>
						))}

					{theme !== 'anko' && (
						<div>
							{promotion && (
								<Text variant='body-s' color='success400'>
									{t('common.freeYear')}
								</Text>
							)}
							<Text variant='body-xs' color='grayscale400'>
								{price === 0 && <>{t('components.shoppingCartItem.afterDiscount')} </>}
								<Trans
									i18nKey={
										billingUnit === 'monthly'
											? 'components.shoppingCartItem.pricePerMonth'
											: 'components.shoppingCartItem.pricePerYear'
									}
									tOptions={{
										price: transformToCurrency(promotion ? priceBeforeDiscount : price),
									}}
									components={{
										bold: <span className='text-grayscale600 font-semibold' />,
									}}
								/>
							</Text>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
