import { addYears, format, getYear, parse } from 'date-fns';

import { InputSelectOption } from '@monorepo/types';

type DateFormat = 'yyyy-MM-dd' | 'dd-MM-yyyy';

export const M_DATE_FORMAT: DateFormat = 'yyyy-MM-dd'; // machine
export const D_DATE_FORMAT: DateFormat = 'dd-MM-yyyy'; // display

export const today = ({
	dateFormat = M_DATE_FORMAT,
	asDate = false,
	dayModifier = 0,
}: {
	dateFormat?: DateFormat;
	asDate?: boolean;
	dayModifier?: number;
} = {}) => {
	const date = new Date();

	date.setDate(date.getDate() + dayModifier);

	return formatDate({ date, dateFormat, asDate });
};

export const formatDate = ({
	date,
	dateFormat = M_DATE_FORMAT,
	asDate = false,
}: {
	date: Date;
	dateFormat?: DateFormat;
	asDate?: boolean;
}) => {
	return asDate ? new Date(format(date, dateFormat)) : format(date, dateFormat);
};

export const transformDateFormat = (
	inputDate: string,
	oldFormat: string = D_DATE_FORMAT,
	newFormat: string = M_DATE_FORMAT
): string => {
	// Parse the input date with the original format (dd-MM-yyyy)
	const parsedDate = parse(inputDate, oldFormat, new Date());

	// Format the parsed date into the desired format (yyyy-MM-dd)
	const transformedDate = format(parsedDate, newFormat);

	return transformedDate;
};

export const getCurrentYear = () => {
	return new Date().getFullYear();
};

export const getPrevYears = (yearsBack = 5): InputSelectOption[] => {
	const currentYear = getCurrentYear(),
		minYear = currentYear - yearsBack,
		years: InputSelectOption[] = [];

	for (let i = currentYear; i > minYear; i--) {
		years.push({
			code: i,
			name: i.toString(),
		});
	}

	return years;
};

export const getNextYear = (): number => {
	const currentDate = new Date();
	const nextYearDate = addYears(currentDate, 1);
	return getYear(nextYearDate);
};

export const getLastNYears = (n = 100): number[] => {
	const currentYear = new Date().getFullYear();
	const yearsArray: number[] = [];

	for (let i = 0; i < n; i++) {
		yearsArray.push(currentYear - i);
	}

	return yearsArray;
};

export const getMonthsInYear = (): { index: number; readable: string }[] => {
	const locale = navigator?.language ?? 'nl-NL';
	const formatter = new Intl.DateTimeFormat(locale, { month: 'long' });
	const months: { index: number; readable: string }[] = [];

	for (let month = 0; month < 12; month++) {
		// Create a date for the first day of each month in a fixed year (e.g., 2020)
		const date = new Date(2020, month, 1);
		months.push({ index: month + 1, readable: formatter.format(date) });
	}

	return months;
};
