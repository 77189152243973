import classNames from 'classnames';
import { motion, PanInfo } from 'framer-motion';
import parse from 'html-react-parser';
import { useTranslation } from 'next-i18next';
import React, { useMemo, useState } from 'react';

import { Question } from '@monorepo/types';

import { parseStatement, slugify } from '../../../../lib/utils';
import { StandardButton } from '../../../DataEntry';
import { Icon } from '../../../Misc';
import { ButtonGroup } from '../../../Misc/';
import { Text } from '../../../TextComponents';
import { TooltipPopup } from '../../Tooltips';

type StatementStackCardProps = {
	index: number;
	total: number;
	question: Question;
	onAnswer: (guid: string, answer: boolean) => void;
};

export const StatementStackCard: React.FC<StatementStackCardProps> = ({ onAnswer, index, total, question }) => {
	const [leaveX, setLeaveX] = useState<`${string}px`>('0px');
	const [direction, setDirection] = useState<'left' | 'right' | 'stationary'>('stationary');
	const { t } = useTranslation(['common']);
	const isDraggable = total - index === 1;

	const handleOnDragEnd = (_e: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
		if (info.offset.x > -15 && info.offset.x < 15) {
			setDirection('stationary');
		}

		if (info.offset.x > 65) {
			setLeaveX('300px');
			setDirection('right');
			onAnswer(question.guid, true);
		}

		if (info.offset.x < -65) {
			setLeaveX('-300px');
			setDirection('left');
			onAnswer(question.guid, false);
		}
	};

	const handleOnDrag = (_e: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
		setDirection(info.offset.x > 0 ? 'right' : 'left');
	};

	const handleOnClick = (direction: 'left' | 'right') => {
		if (direction === 'left') {
			setLeaveX('-300px');
			onAnswer(question.guid, false);
		}

		if (direction === 'right') {
			setLeaveX('300px');
			onAnswer(question.guid, true);
		}
	};

	// Animation object that moves the card from under the stack to the top
	const animate = useMemo(() => {
		if (total - index === 1) {
			return {
				marginTop: '0px',
				opacity: 1,
				x: 0,
			};
		}

		if (total - index === 2) {
			return {
				marginTop: '10px',
				opacity: 0.6,
				x: 0,
			};
		}

		if (total - index === 3) {
			return {
				marginTop: '20px',
				opacity: 0.3,
				x: 0,
			};
		}

		return {
			marginTop: '20px',
			opacity: 0,
			x: 0,
		};
	}, [total, index]);

	return (
		<>
			<motion.div
				drag={isDraggable ? 'x' : undefined}
				dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
				onDrag={(a, b) => handleOnDrag(a, b)}
				onDragEnd={(a, b) => handleOnDragEnd(a, b)}
				onDragTransitionEnd={() => setDirection('stationary')}
				dragElastic={1}
				initial={{ scale: 1 }}
				animate={{ ...animate }}
				exit={{ x: leaveX, scale: 1.05, opacity: 0, transition: { duration: 0.2 } }}
				whileTap={{ scale: isDraggable ? 1.05 : 1 }}
				className={'shadow-2 bg-grayscale0 rounded-18 absolute h-[250px] w-full p-24 max-md:cursor-grab'}
				data-testid='statement-card-tinder'
				layout
				layoutId={`statement-stack-card-${question.guid}`}>
				{question.tooltips &&
					question.tooltips.map(({ string, tooltip }) => (
						<TooltipPopup
							key={`tooltip-container-${slugify(string)}`}
							id={`tooltip-${slugify(string)}`}
							content={parse(tooltip)}
						/>
					))}
				<div className={classNames('flex h-full flex-col space-y-16', animate.opacity !== 1 && 'invisible')}>
					<div className='flex flex-1 space-x-20 max-sm:overflow-y-auto'>
						<Icon name={question.icon_name} color='primaryMain' size='md' />
						<Text
							variant='body-m'
							color='grayscale600'
							className='prose prose-p:m-0 prose-li:m-0 prose-li:p-0 prose-ul:mx-0 prose-ul:mb-0 prose-ul:mt-4 prose-ul:px-20 prose-ul:list-disc prose-p:text-grayscale600 prose-li:text-grayscale500 prose-li:marker:text-inherit'
							data-testid='statement-card-question'>
							{parseStatement(question.question, question.tooltips)}
						</Text>
					</div>
					<ButtonGroup>
						<StandardButton
							variant='secondary'
							size='sm'
							iconLeft='times'
							label={t('common.incorrect')}
							className={classNames('transition-opacity', direction === 'right' && 'opacity-0')}
							onClick={() => handleOnClick('left')}
							data-testid='statement-stack-card-button-no'
						/>
						<StandardButton
							variant='cta'
							size='sm'
							iconLeft='check'
							label={t('common.correct')}
							className={classNames('transition-opacity', direction === 'left' && 'opacity-0')}
							onClick={() => handleOnClick('right')}
							data-testid='statement-stack-card-button-yes'
						/>
					</ButtonGroup>
				</div>
			</motion.div>
		</>
	);
};
