import { FC, useState } from 'react';

import { ChoiceCard } from '@monorepo/components/DataDisplay';
import { Form } from '@monorepo/components/DataEntry';
import { AffinityGroupSchema } from '@monorepo/shared/lib/schemas';
import { slugify } from '@monorepo/shared/lib/utils';
import { AffinityGroupOptions, Question } from '@monorepo/types';

type AffinityGroupFormProps = {
	question: Question;
	onSubmit: ({ affinityGroup }: AffinityGroupSchema) => void;
};

export const AffinityGroupForm: FC<AffinityGroupFormProps> = ({ question, onSubmit }) => {
	const [clickedButton, setClickedButton] = useState<string>('');

	const submit = (key: AffinityGroupOptions) => {
		setClickedButton(key);
		onSubmit({ affinityGroup: key });
	};

	return (
		<Form.Context<AffinityGroupSchema>>
			{({}) => {
				return (
					<Form id='property-ownership-form'>
						<Form.Group className='space-y-24'>
							{question?.response_options?.map(({ key, value }) => (
								<ChoiceCard
									title={value}
									isLoading={clickedButton === key}
									disabled={clickedButton !== ''}
									onClick={() => submit(key as AffinityGroupOptions)}
									checked={question?.answer_readable === value}
									explicitSuccess={question?.answer_readable === value}
									key={`property-ownership-${slugify(value)}`}
									imageSrc={key !== 'N' ? `/affinitygroups/${key}/logo/logo-selectbox.svg` : undefined}
									text={value}
									data-testid={`affinity-group-form-${value}`}
								/>
							))}
						</Form.Group>
					</Form>
				);
			}}
		</Form.Context>
	);
};
