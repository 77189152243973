import { FC } from 'react';

import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { InsuredSection } from '@marketing/components';

export const handleSectionInsured = <P extends object>({ elements }: HandlerPropsV2): FC<P> => {
	const titleSubTextElement = elements.find(({ identifier }) => identifier === 'title-subtext') as CmsElement;
	const insuredItems = elements.filter(({ identifier }) => identifier === 'insured');
	const unsuredItems = elements.filter(({ identifier }) => identifier === 'unsured');

	const title = getProperty('title', titleSubTextElement.custom_fields);
	const subtext = getProperty('subtext', titleSubTextElement.custom_fields);

	const _insured = insuredItems.map((insuredItem) => {
		const title = getProperty('title', insuredItem.custom_fields);
		const icon = getProperty('icon', insuredItem.custom_fields);

		return {
			title: `${title}`,
			icon: `${icon}`,
			isInsured: true,
		};
	});

	const _unsured = unsuredItems.map((insuredItem) => {
		const title = getProperty('title', insuredItem.custom_fields);
		const icon = getProperty('icon', insuredItem.custom_fields);

		return {
			title: `${title}`,
			icon: `${icon}`,
			isInsured: false,
		};
	});

	return (props) => (
		<InsuredSection
			{...props}
			insured={[..._insured, ..._unsured]}
			className='bg-primary50 py-48 md:pb-96 md:pt-64'
			title={`${title}`}
			subtitle={`${subtext}`}
		/>
	);
};
