import type { Themes } from '@monorepo/types';
import { affinityGroups, ReactSelectOption } from '@monorepo/types';

import { numberToPixels } from '../helpers/helpers';
import { sortAlphabetically } from '../sort/sort';
import { capitalizeFirstLetter } from '../text/text';

const mainTheme: Themes['maia'] = {
	colors: {
		primaryMain: '#1A2A66',
		secondaryMain: '#00E57E',
		primary600: '#000000',
		primary400: '#2A3C7E',
		primary300: '#4B65C3',
		primary200: '#B3BEE5',
		primary100: '#E4E9F6',
		primary50: '#F1F3FE',
		secondary600: '#00C26A',
		secondary400: '#31EA96',
		secondary300: '#61EFAF',
		secondary200: '#92F3C7',
		secondary100: '#C2F8E0',
		secondary50: '#F3FDF8',
		grayscale600: '#101730',
		grayscale500: '#3B4155',
		grayscale400: '#666B7A',
		grayscale300: '#9196A0',
		grayscale200: '#CED1D4',
		grayscale100: '#E7EAEA',
		grayscale50: '#F7F8F8',
		grayscale0: '#FFFFFF',
		error500: '#A40923',
		error400: '#AA1220',
		error300: '#E72233',
		error200: '#FDB4BB',
		error50: '#FEEBED',
		warning500: '#B24A00',
		warning400: '#E57700',
		warning300: '#FF9900',
		warning200: '#FFDD99',
		warning50: '#FFF6E5',
		success500: '#0B4119',
		success400: '#14712B',
		success300: '#1EA941',
		success200: '#45D369',
		success50: '#E9FBEE',
		info500: '#0F2557',
		info400: '#1C429C',
		info300: '#265CD9',
		info200: '#93ADEC',
		info50: '#F2F5FD',
	},
	text: {
		'display-1': { desktop: numberToPixels(56), mobile: numberToPixels(40) },
		'display-2': { desktop: numberToPixels(48), mobile: numberToPixels(32) },
		'display-3': { desktop: numberToPixels(40), mobile: numberToPixels(24) },
		'display-4': { desktop: numberToPixels(32), mobile: numberToPixels(22) },
		'display-5': { desktop: numberToPixels(24), mobile: numberToPixels(20) },
		'display-6': { desktop: numberToPixels(20), mobile: numberToPixels(18) },
		'body-l': { desktop: numberToPixels(18), mobile: numberToPixels(16) },
		'body-m': { desktop: numberToPixels(16), mobile: numberToPixels(14) },
		'body-s': { desktop: numberToPixels(14), mobile: numberToPixels(12) },
		'body-xs': { desktop: numberToPixels(12), mobile: numberToPixels(11) },
		'body-xxs': { desktop: numberToPixels(10), mobile: numberToPixels(10) },
	},
	lineHeight: {
		'display-1': { desktop: numberToPixels(60), mobile: numberToPixels(44) },
		'display-2': { desktop: numberToPixels(52), mobile: numberToPixels(36) },
		'display-3': { desktop: numberToPixels(44), mobile: numberToPixels(30) },
		'display-4': { desktop: numberToPixels(40), mobile: numberToPixels(28) },
		'display-5': { desktop: numberToPixels(32), mobile: numberToPixels(26) },
		'display-6': { desktop: numberToPixels(24), mobile: numberToPixels(24) },
		'body-l': { desktop: numberToPixels(24), mobile: numberToPixels(22) },
		'body-m': { desktop: numberToPixels(22), mobile: numberToPixels(20) },
		'body-s': { desktop: numberToPixels(20), mobile: numberToPixels(16) },
		'body-xs': { desktop: numberToPixels(16), mobile: numberToPixels(14) },
		'body-xxs': { desktop: numberToPixels(14), mobile: numberToPixels(14) },
	},
	depth: {
		elevation1: '0px 2px 4px rgba(0, 30, 70, 0.04), 0px 4px 20px rgba(0, 30, 70, 0.08)',
		elevation2: '0px 16px 72px rgba(0, 30, 70, 0.08), 0px 8px 16px rgba(0, 30, 70, 0.04)',
		elevation3: '0px 16px 120px rgba(0, 30, 70, 0.08), 0px 8px 24px rgba(0, 30, 70, 0.04)',
		elevation4: '0px 8px 24px rgba(0, 30, 70, 0.04), 0px 72px 180px rgba(0, 30, 70, 0.12)',
		elevation5: '0px 8px 32px rgba(0, 30, 70, 0.04), 0px 72px 180px rgba(0, 30, 70, 0.16)',
	},
	borderRadius: {
		4: numberToPixels(4),
		6: numberToPixels(6),
		12: numberToPixels(12),
		16: numberToPixels(16),
		18: numberToPixels(18),
		24: numberToPixels(24),
		40: numberToPixels(40),
	},
	spacing: {
		4: numberToPixels(4),
		8: numberToPixels(8),
		12: numberToPixels(12),
		16: numberToPixels(16),
		20: numberToPixels(20),
		24: numberToPixels(24),
		32: numberToPixels(32),
		40: numberToPixels(40),
		48: numberToPixels(48),
		50: numberToPixels(50),
		56: numberToPixels(56),
		64: numberToPixels(64),
		80: numberToPixels(80),
		96: numberToPixels(96),
		104: numberToPixels(104),
		124: numberToPixels(124),
	},
};

const nvmTheme: Themes['nvm'] = {
	...mainTheme,
	colors: {
		...mainTheme.colors,
		primaryMain: '#0A5AD2',
		primary600: '#073C8D',
		primary400: '#3678DA',
		primary300: '#7AA5E6',
		primary200: '#A5C2EE',
		primary100: '#CCDCF5',
		primary50: '#EEF3FC',
		secondaryMain: '#009F4D',
		secondary600: '#006B34',
		secondary400: '#2EB06D',
		secondary300: '#73CA9D',
		secondary200: '#9BDAB9',
		secondary100: '#CBECDB',
		secondary50: '#EDF8F2',
	},
};

const vboTheme: Themes['vbo'] = {
	...mainTheme,
	colors: {
		...mainTheme.colors,
		primaryMain: '#0E4E95',
		primary600: '#093464',
		primary400: '#3669A0',
		primary300: '#658EBB',
		primary200: '#A7BED8',
		primary100: '#CAD8E7',
		primary50: '#EEF2F7',
		secondaryMain: '#00A6D6',
		secondary600: '#006F8F',
		secondary400: '#2EB6DD',
		secondary300: '#7FD2EB',
		secondary200: '#A7E0F1',
		secondary100: '#CAEDF7',
		secondary50: '#E9F7FC',
	},
};

const vastgoedproTheme: Themes['vastgoedpro'] = {
	...mainTheme,
	colors: {
		...mainTheme.colors,
		primaryMain: '#353C43',
		primary600: '#24282D',
		primary400: '#5B6066',
		primary300: '#818589',
		primary200: '#B2B5B8',
		primary100: '#D5D6D8',
		primary50: '#F2F2F3',
		secondaryMain: '#EA6A16',
		secondary600: '#CE4B13',
		secondary400: '#EE8541',
		secondary300: '#F2A16C',
		secondary200: '#F6C4A2',
		secondary100: '#FAE2D1',
		secondary50: '#FDF3EC',
	},
};

const onderhoudnlTheme: Themes['onderhoudnl'] = {
	...mainTheme,
	colors: {
		...mainTheme.colors,
		primaryMain: '#00B1EB',
		primary600: '#00779D',
		primary400: '#2EBFEF',
		primary300: '#7FD8F5',
		primary200: '#AAE5F8',
		primary100: '#CBEFFB',
		primary50: '#E7F8FD',
		secondaryMain: '#DA362D',
		secondary600: '#92241E',
		secondary400: '#E15A53',
		secondary300: '#E77E79',
		secondary200: '#F1B5B1',
		secondary100: '#F8DAD8',
		secondary50: '#FDF3F2',
	},
};

const ankoTheme: Themes['anko'] = {
	...mainTheme,
	colors: {
		...mainTheme.colors,
		primaryMain: '#222222',
		primary600: '#171717',
		primary400: '#4a4a4a',
		primary300: '#7d7d7d',
		primary200: '#b0b0b0',
		primary100: '#d6d6d6',
		primary50: '#f2f2f2',
		secondaryMain: '#ed7d00',
		secondary600: '#db6600',
		secondary400: '#f0942e',
		secondary300: '#f5b670',
		secondary200: '#f9ce9f',
		secondary100: '#fbe5cb',
		secondary50: '#fdf2e5',
	},
};

const cybermeisterTheme: Themes['cybermeister'] = {
	...mainTheme,
	colors: {
		...mainTheme.colors,
		primaryMain: '#60269E',
		primary600: '#40196A',
		primary400: '#946DBE',
		primary300: '#C9B5DE',
		primary200: '#DFD4EC',
		primary100: '#EFE9F5',
		primary50: '#F7F4FA',
		secondaryMain: '#333',
		secondary600: '#222',
		secondary400: '#767676',
		secondary300: '#B9B9B9',
		secondary200: '#D6D6D6',
		secondary100: '#EAEAEA',
		secondary50: '#F5F5F5',
	},
};

const meestersgildeTheme: Themes['meestersgilde'] = {
	...mainTheme,
	colors: {
		...mainTheme.colors,
		primaryMain: '#002A46',
		primary600: '#001C2F',
		primary400: '#547083',
		primary300: '#A8B6C0',
		primary200: '#CCD4DA',
		primary100: '#E5E9EC',
		primary50: '#F2F4F6',
		secondaryMain: '#E72D80',
		secondary600: '#9B1E56',
		secondary400: '#EF72AA',
		secondary300: '#F7B7D4',
		secondary200: '#FAD5E6',
		secondary100: '#FDEAF2',
		secondary50: '#FEF4F9',
	},
};

const trustooTheme: Themes['trustoo'] = {
	...mainTheme,
};

const beautyCursusTheme: Themes['deonlinebeautycursussen'] = {
	...mainTheme,
	colors: {
		...mainTheme.colors,
		primaryMain: '#54595F',
		primary600: '#383C40',
		primary400: '#8C9094',
		primary300: '#C5C7C9',
		primary200: '#DDDEDF',
		primary100: '#EEEEEF',
		primary50: '#F6F7F7',
		secondaryMain: '#DAB7B1',
		secondary600: '#927B77',
		secondary400: '#E6CFCB',
		secondary300: '#F2E7E4',
		secondary200: '#F2E7E4',
		secondary100: '#F8F1EF',
		secondary50: '#FBF8F7',
	},
};

const nkcTheme: Themes['nkc'] = {
	...mainTheme,
	colors: {
		...mainTheme.colors,
		primaryMain: '#1A4948',
	},
};

const marengoTheme: Themes['marengo'] = {
	...mainTheme,
	colors: {
		...mainTheme.colors,
		primaryMain: '#1B9FAC',
		primary600: '#126B73',
		primary400: '#66BFC7',
		primary300: '#B1DEE3',
		primary200: '#D1ECEE',
		primary100: '#E8F5F7',
		primary50: '#F4FAFB',
		secondaryMain: '#CD9336',
		secondary600: '#896224',
		secondary400: '#DDB778',
		secondary300: '#EEDABB',
		secondary200: '#F5E9D7',
		secondary100: '#FAF4EB',
		secondary50: '#FCFAF5',
	},
};

export const themes: Themes = {
	maia: mainTheme,
	nvm: nvmTheme,
	vbo: vboTheme,
	vastgoedpro: vastgoedproTheme,
	onderhoudnl: onderhoudnlTheme,
	anko: ankoTheme,
	cybermeister: cybermeisterTheme,
	meestersgilde: meestersgildeTheme,
	trustoo: trustooTheme,
	deonlinebeautycursussen: beautyCursusTheme,
	nkc: nkcTheme,
	marengo: marengoTheme,
};

export const salesChannels: ReactSelectOption[] = affinityGroups
	.map((group) => {
		return {
			value: group,
			label:
				group === 'vastgoedpro' || group === 'maia' || group === 'meestersgilde'
					? capitalizeFirstLetter(group)
					: group === 'onderhoudnl'
						? 'OnderhoudNL'
						: group.toUpperCase(),
			icon: `/affinitygroups/${group}/logo/logo-selectbox.svg`,
		};
	})
	.sort((a, b) => sortAlphabetically(a.value, b.value));
