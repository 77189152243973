import { DefaultSeoProps } from 'next-seo';
import { NextRouter } from 'next/router';

import { affinityGroupLabelUrlMap } from '@monorepo/shared/lib/utils';
import { ThemeNames } from '@monorepo/types';

// SEO sitenames
export const seoSiteNameMap: Record<ThemeNames, string> = {
	maia: 'Māia',
	nvm: 'Schouten Verzekeringsdienst NVM',
	vbo: 'Schouten Zekerheid',
	vastgoedpro: 'Vastgoedpro Verzekeringen',
	onderhoudnl: 'OnderhoudNL Verzekeringen',
	anko: 'ANKO Verzekeringsdienst',
	cybermeister: 'Māia',
	meestersgilde: 'Māia',
	trustoo: 'Trustoo',
	deonlinebeautycursussen: 'Online Beauty Cursus',
	nkc: 'NKC',
	marengo: 'Marengo',
};

// SEO default title
export const seoTitle = 'Zelfverzekerd ondernemen';
// export const SEO_TWITTER_HANDLE = '@maiainsure';

// SEO title template
export const seoTitleTemplateMap: Record<ThemeNames, string> = {
	maia: '%s | Māia',
	nvm: '%s | Schouten Verzekeringsdienst NVM',
	vbo: '%s | Schouten Zekerheid',
	vastgoedpro: '%s | Vastgoedpro Verzekeringen',
	onderhoudnl: '%s | OnderhoudNL Verzekeringen',
	anko: '%s | ANKO Verzekeringsdienst',
	cybermeister: '%s | Māia',
	meestersgilde: '%s | Māia',
	trustoo: '%s | Māia',
	deonlinebeautycursussen: '%s | Māia',
	nkc: '%s | NKC',
	marengo: '%s | Marengo',
};

// SEO description map
export const seoDescriptionMap: Record<ThemeNames, string> = {
	maia: `${seoSiteNameMap.maia} gelooft in een gezonde Hollandse economie waarin jij zelfverzekerd kunt ondernemen. Zorgeloos, omdat je weet dat het goed zit - ${seoSiteNameMap.maia} is er voor jou.`,
	nkc: `${seoSiteNameMap.nkc} gelooft in een gezonde Hollandse economie waarin jij zelfverzekerd kunt ondernemen. Zorgeloos, omdat je weet dat het goed zit - ${seoSiteNameMap.nkc} is er voor jou.`,
	nvm: `${seoSiteNameMap.nvm} gelooft in een gezonde Hollandse economie waarin jij zelfverzekerd kunt ondernemen. Zorgeloos, omdat je weet dat het goed zit - ${seoSiteNameMap.nvm} is er voor jou.`,
	vbo: `${seoSiteNameMap.vbo} gelooft in een gezonde Hollandse economie waarin jij zelfverzekerd kunt ondernemen. Zorgeloos, omdat je weet dat het goed zit - ${seoSiteNameMap.vbo} is er voor jou.`,
	vastgoedpro: `${seoSiteNameMap.vastgoedpro} gelooft in een gezonde Hollandse economie waarin jij zelfverzekerd kunt ondernemen. Zorgeloos, omdat je weet dat het goed zit - ${seoSiteNameMap.vastgoedpro} is er voor jou.`,
	onderhoudnl: `${seoSiteNameMap.onderhoudnl} gelooft in een gezonde Hollandse economie waarin jij zelfverzekerd kunt ondernemen. Zorgeloos, omdat je weet dat het goed zit - ${seoSiteNameMap.onderhoudnl} is er voor jou.`,
	anko: `${seoSiteNameMap.anko} gelooft in een gezonde Hollandse economie waarin jij zelfverzekerd kunt ondernemen. Zorgeloos, omdat je weet dat het goed zit - ${seoSiteNameMap.onderhoudnl} is er voor jou.`,
	cybermeister: `${seoSiteNameMap.maia} gelooft in een gezonde Hollandse economie waarin jij zelfverzekerd kunt ondernemen. Zorgeloos, omdat je weet dat het goed zit - ${seoSiteNameMap.cybermeister} is er voor jou.`,
	meestersgilde: `${seoSiteNameMap.maia} gelooft in een gezonde Hollandse economie waarin jij zelfverzekerd kunt ondernemen. Zorgeloos, omdat je weet dat het goed zit - ${seoSiteNameMap.meestersgilde} is er voor jou.`,
	trustoo: `${seoSiteNameMap.maia} gelooft in een gezonde Hollandse economie waarin jij zelfverzekerd kunt ondernemen. Zorgeloos, omdat je weet dat het goed zit - ${seoSiteNameMap.trustoo} is er voor jou.`,
	deonlinebeautycursussen: `${seoSiteNameMap['deonlinebeautycursussen']} gelooft in een gezonde Hollandse economie waarin jij zelfverzekerd kunt ondernemen. Zorgeloos, omdat je weet dat het goed zit - ${seoSiteNameMap.deonlinebeautycursussen} is er voor jou.`,
	marengo: `${seoSiteNameMap['marengo']} gelooft in een gezonde Hollandse economie waarin jij zelfverzekerd kunt ondernemen. Zorgeloos, omdat je weet dat het goed zit - ${seoSiteNameMap.deonlinebeautycursussen} is er voor jou.`,
};

// SEO default config
export const seoDefaultConfig: DefaultSeoProps = {
	title: seoTitle,
	titleTemplate: seoTitleTemplateMap.maia,
	defaultTitle: seoTitle,
	description: seoDescriptionMap.maia,
	openGraph: {
		type: 'website',
		title: seoTitle,
		description: seoDescriptionMap.maia,
		siteName: seoSiteNameMap.maia,
		locale: 'nl_NL',
		url: affinityGroupLabelUrlMap?.maia, // TODO Update
		images: [
			{
				url: `${affinityGroupLabelUrlMap?.maia}public/`,
				alt: seoTitle,
				width: 1200,
				height: 630,
			},
		],
	},
	additionalMetaTags: [
		{
			property: 'charset',
			content: 'UTF-8',
		},
		{
			name: 'viewport',
			content:
				'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, shrink-to-fit=no, viewport-fit=cover',
		},
	],
};

// ogImageBuilder
export const ogImageBuilder = (theme: ThemeNames | 'real-estate' = 'maia') => {
	let ogImageUrl: string;
	if (typeof window !== 'undefined') {
		ogImageUrl = window.location.origin;
	} else {
		ogImageUrl = `${process.env.NEXT_PUBLIC_WEBAPP_URL}`;
	}

	return {
		siteName: theme === 'real-estate' ? seoSiteNameMap.maia : seoSiteNameMap[theme],
		description: theme === 'real-estate' ? seoDescriptionMap.maia : seoDescriptionMap[theme],
		images: [
			{
				url: `${ogImageUrl}${theme === 'maia' ? '/images/meta/og-image.png' : `/api/og/${theme}`}`,
				alt: seoTitle,
				width: 1200,
				height: 630,
			},
		],
	};
};

// Canonical URL
export const canonicalUrlBuilder = (pathname: string) => {
	return `${process.env.NEXT_PUBLIC_WEBAPP_URL}${pathname}`;
};

export function filterEmptyQuery(queries: Array<NextRouter['query']>): NextRouter['query'] {
	return queries.reduce(
		(acc, query) => {
			if (query) {
				Object.entries(query).forEach(([key, value]) => {
					if (value) {
						acc[key] = value;
					}
				});
			}
			return acc;
		},
		{} as NextRouter['query']
	);
}
