import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useId } from 'react';
import { Control, Controller, FieldValues, Path, PathValue } from 'react-hook-form';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { ReactSelectOption } from '@monorepo/types';

import {
	disabledClasses,
	focusClasses,
	FormBaseInputDefaultProps,
	FormBaseInputProps,
	hoverClasses,
} from '../FormBaseInput/FormBaseInput';
import { FormHelper } from '../FormHelper/FormHelper';

export type FormDropdownProps<TFormValues extends FieldValues> = {
	options: ReactSelectOption[];
	hint?: string;
	isLoading?: boolean;
	createable?: boolean;
	hideError?: boolean;
	control: Control<TFormValues>;
	'data-testid'?: string;
} & Omit<FormBaseInputDefaultProps<TFormValues>, 'startIcon' | 'endIcon' | 'onEndIconClick'> &
	Omit<FormBaseInputProps, 'name' | 'errors' | 'type'>;

export const FormDropdown = <TFormValues extends Record<string, unknown>>({
	name,
	errors,
	hint,
	createable,
	hideError,
	placeholder,
	control,
	defaultValue,
	options = [],
	isLoading = false,
	className,
	...props
}: FormDropdownProps<TFormValues>) => {
	const { t } = useTranslation(['common']);
	const SelectComponent = createable ? CreatableSelect : Select;
	const instanceId = useId();

	return (
		<FormHelper errors={errors} hint={hint} hideError={hideError}>
			{({ isError }) => (
				<Controller
					control={control}
					defaultValue={defaultValue as PathValue<TFormValues, Path<TFormValues>>}
					name={name}
					render={({ field: { onChange, onBlur, value, ref } }) => {
						return (
							<SelectComponent
								instanceId={instanceId}
								components={{
									SelectContainer: (_props) => (
										<components.SelectContainer
											{..._props}
											innerProps={Object.assign({}, _props.innerProps, {
												'data-testid': props['data-testid'] ?? 'dropdown-container',
											})}
										/>
									),
									Input: (props) => <components.Input {...props} aria-activedescendant={undefined} />,
								}}
								isLoading={isLoading}
								unstyled={true}
								isSearchable
								isMulti={false}
								classNamePrefix='react-select'
								classNames={{
									container: (state) =>
										classNames(
											className,
											!className?.includes('z-') && 'z-20',
											'relative',
											state.isDisabled ? '!cursor-not-allowed' : '!cursor-pointer'
										),
									control: (state) =>
										classNames(
											'rounded-12 typography-body-m border peer p-20 !transition-colors placeholder:font-light !duration-300 ease-out',
											disabledClasses,
											hoverClasses,
											focusClasses,
											isError && 'border-error300',
											!isError && 'border-grayscale200',
											state.isDisabled
												? '!cursor-not-allowed border-grayscale200 bg-grayscale50 text-grayscale300 font-light'
												: '!cursor-pointer text-grayscale500'
										),
									option: (state) =>
										classNames(
											state.isSelected ? 'text-primary300' : 'text-grayscale500',
											'!cursor-pointer typography-body-m font-light px-16 py-8 hover:bg-grayscale50'
										),
									menu: () => 'border border-grayscale100 rounded-6 shadow-1 py-8 mt-8 bg-grayscale0',
									noOptionsMessage: () => 'typography-body-s text-grayscale500',
									dropdownIndicator: () => 'border-l border-l-grayscale200 pl-24',
								}}
								isDisabled={props.disabled}
								value={options.find((option) => option.value === value) ?? undefined}
								noOptionsMessage={() => t('common.selectNoOptions')}
								defaultValue={options.find((option) => option.value === value) ?? undefined}
								onBlur={onBlur}
								onChange={(select) => onChange(select?.value)}
								options={options}
								placeholder={placeholder}
								ref={ref}
								formatCreateLabel={(inputValue) => t('common.selectCreateOption', { value: inputValue })}
							/>
						);
					}}
				/>
			)}
		</FormHelper>
	);
};
