import { FC } from 'react';
import ReactMarkdown, { Components } from 'react-markdown';
import { ReactMarkdownProps } from 'react-markdown/lib/complex-types';
import remarkGfm from 'remark-gfm';

import { Text } from '@monorepo/components/TextComponents';

import { HandlerProps } from '@cms/componentMapper/componentMapper';

const Heading2 = (props: ReactMarkdownProps) => (
	<Text as='h2' variant='body-l' weight='medium' color='grayscale600' {...props} />
);

const Em = (props: ReactMarkdownProps) => <span className='font-medium' {...props} />;

const Bold = (props: ReactMarkdownProps) => <strong {...props} className='font-medium' color='inherit' />;

const Paragraph = (props: ReactMarkdownProps) => <Text as='p' variant='body-m' color='grayscale500' {...props} />;

const Table = (props: ReactMarkdownProps) => (
	<div className='border-grayscale100 rounded-18 border'>
		<table {...props} className='w-full' />
	</div>
);
const TableHead = (props: ReactMarkdownProps) => (
	<thead {...props} className='text-grayscale600 border-b-grayscale100 border-b' />
);
const TableBody = (props: ReactMarkdownProps) => <tbody {...props} className='' />;
const TableHeader = (props: ReactMarkdownProps) => (
	<th {...props} className='typography-body-m text-grayscale600 px-24 py-16' />
);
const TableData = (props: ReactMarkdownProps) => (
	<td
		{...props}
		className='typography-body-xs text-grayscale300 [&>strong]:text-body-m-mobile md:[&>strong]:text-body-m-mobile [&>strong]:text-grayscale600 px-24 py-16'
	/>
);
const TableRow = (props: ReactMarkdownProps) => <tr {...props} className='border-b-grayscale100 border-b' />;

const Ul = (props: ReactMarkdownProps) => <ul {...props} className='mt-4 list-outside list-disc pl-24' />;
const Li = (props: ReactMarkdownProps) => <Text as='li' variant='body-m' color='grayscale500' {...props} />;

export const mdComponents: Components = {
	h2: Heading2,
	p: Paragraph,
	b: Bold,
	em: Em,
	strong: Bold,
	table: Table,
	thead: TableHead,
	tbody: TableBody,
	th: TableHeader,
	td: TableData,
	tr: TableRow,
	ul: Ul,
	li: Li,
};

export const handleMarkdown = ({ component }: HandlerProps): FC => {
	return () => (
		<ReactMarkdown remarkPlugins={[remarkGfm]} components={mdComponents}>
			{`${component?.markdown}`}
		</ReactMarkdown>
	);
};
