import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { FC, ReactNode, useMemo } from 'react';

import { BaseCard } from '@monorepo/components/DataDisplay';
import { sortAscending } from '@monorepo/shared/lib/utils';
import { BillingUnits, CalculationProduct, CmsContainer, CmsContainers } from '@monorepo/types';

import { LargeSelectableProductCard, SmallProductCard } from '@funnel/components';

type Props = {
	product: CalculationProduct;
	content?: CmsContainer;
	billingUnit?: BillingUnits;
	form?: ReactNode;
	isLoading?: boolean;
	isCalculating?: boolean;
	variant?: 'sm' | 'lg';
	className?: string;
	onCtaClick?: () => void;
	onMoreInfoClick: (guid: string, container: CmsContainers) => void;
	onEditPrecondition?: () => void;
};

export const SelectableProductCard: FC<Props> = ({
	product,
	content,
	billingUnit = 'monthly',
	form,
	isLoading,
	isCalculating,
	variant = 'sm',
	className = '',
	onCtaClick,
	onMoreInfoClick,
	onEditPrecondition,
}) => {
	const { t } = useTranslation(['common']);

	const isPreconditionsAnswers = useMemo(
		() => product?.preconditions?.every(({ answer }) => answer),
		[product?.preconditions]
	);
	/**
	 * If the product is active, grab the active option from that product, else the cheapest
	 */
	const price = useMemo(() => {
		const optionToGetPriceFrom = product.is_active
			? product.options.filter(({ show_on_website }) => show_on_website).find((option) => option.is_active)
			: [...product?.options]
					.filter(({ show_on_website }) => show_on_website)
					.sort((a, b) => sortAscending(a.price.premium_before_discount, b.price.premium_before_discount))[0];

		if (optionToGetPriceFrom?.price.promotion === 'first_year') {
			return optionToGetPriceFrom.price.premium_before_discount;
		}

		if (product?.is_active && optionToGetPriceFrom?.is_calculated) {
			return optionToGetPriceFrom?.price.premium_after_tax;
		}

		if (product.minimum_price !== null) {
			return product.minimum_price;
		}

		return 0;
	}, [product.options, product.is_active, product.minimum_price]);

	/**
	 * Find the deductible to show in a tag
	 * If we have multiple deductibles (e.g. when a customer can choose), then return undefined and dont show it
	 */
	const deductible = useMemo(() => {
		const deductibleValues = product.options
			.flatMap((item) => item.specs)
			.filter((spec) => spec.is_deductible && (spec.deductible_type === 'default' || spec.deductible_type === 'case'))
			.map((spec) => spec.value);

		const allEqual = deductibleValues.every((value) => value === deductibleValues[0]);

		return allEqual ? deductibleValues[0] : undefined;
	}, [product.options]);

	/**
	 * Find out if we have a running promotion. If so, return it
	 */
	const runningPromotion = useMemo(
		() => product.options.find((option) => option.is_active)?.price.promotion,
		[product.options]
	);

	const isActive = useMemo(
		() => product.is_active && isPreconditionsAnswers,
		[product.is_active, isPreconditionsAnswers]
	);

	if (content === null) return <></>;

	return (
		<BaseCard
			isElevated={!isActive}
			enableEffects={variant === 'sm'}
			className={cn(
				className,
				variant === 'sm' && 'pointer-events-none', // Disable pointer events on small card and enable it on child buttons
				isActive && 'border-success300 outline-success300',
				!isActive && 'border-transparent outline-transparent',
				'border p-24 outline outline-1'
			)}
			data-testid={`selectable-product-card-${product.name}`}>
			{variant === 'sm' ? (
				<SmallProductCard
					billingUnit={billingUnit}
					product={product}
					content={content?.elements}
					price={price}
					deductible={deductible ? +deductible : undefined}
					runningPromotion={runningPromotion}
					ctaText={t('common.ctaCloseOff')}
					onCtaClick={onCtaClick}
					onMoreInfoClick={onMoreInfoClick}
				/>
			) : variant === 'lg' ? (
				<LargeSelectableProductCard
					isActive={isActive}
					billingUnit={billingUnit}
					content={content?.elements}
					form={form}
					price={price}
					deductible={deductible ? +deductible : undefined}
					runningPromotion={runningPromotion}
					onCtaClick={onCtaClick}
					onMoreInfoClick={onMoreInfoClick}
					isLoading={isLoading}
					isCalculating={isCalculating}
					onEditPrecondition={onEditPrecondition}
					name={product.name}
					icon={product.icon_name}
					isProductActive={product.is_active}
					guid={product.guid}
					render_type={product.render_type}
					preconditions={product.preconditions}
				/>
			) : null}
		</BaseCard>
	);
};
