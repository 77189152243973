import cn from 'classnames';
import dynamic from 'next/dynamic';
import { FC } from 'react';

import { ThemeColors } from '@monorepo/types';

import { Text } from '../Text/Text';

type Props = {
	leadingText?: string;
	price: number;
	afterText: string;
	textColor?: ThemeColors | 'inherit';
	className?: string;
};

const AnimateNumber = dynamic(() => import('../../../Misc').then((mod) => mod.AnimateNumber), {
	ssr: false,
});

export const EmphasizedPriceFormat: FC<Props> = ({
	leadingText,
	price,
	afterText,
	textColor = 'info300',
	className = '',
}) => {
	const [euros = '0', cents = '00'] = price.toFixed(2).split('.');
	const eurosNumber = +euros,
		centsNumber = +cents;

	return (
		<div className={cn(className, 'relative flex w-fit flex-col overflow-hidden')}>
			{leadingText && (
				<Text variant='body-xs' color={textColor}>
					{leadingText}
				</Text>
			)}

			<div className='flex flex-row flex-wrap'>
				<Text variant='display-4' weight='semibold' color={textColor} className='flex items-center leading-none'>
					<span className='pr-4'>{'€'}</span>
					<AnimateNumber value={eurosNumber} />
					<span>{','}</span>
				</Text>
				<Text variant='body-l' weight='semibold' color={textColor} className='relative leading-none md:top-[2px]'>
					{centsNumber.toString().length === 1 && <AnimateNumber value={0} />}
					<AnimateNumber value={centsNumber} />
				</Text>

				<Text
					as='span'
					variant='body-xs'
					color={textColor}
					className='relative bottom-0 right-[2px] ml-auto self-end leading-none max-md:top-[2px]'>
					{afterText}
				</Text>
			</div>
		</div>
	);
};
