import { Address, ThemeNames, type PIX } from '@monorepo/types';

/**
 * Convers a deeply nested object into a flattened object and with slugified keys
 * @param obj
 * @param parent
 * @param res
 */
export function flatAndSlugifyObj<T>(
	obj: T,
	parent: string | null = null,
	res: { [key: string]: string } = {}
): { [key: string]: string } {
	for (const key in obj) {
		const propName = parent ? parent + '-' + key : key;
		if (typeof obj[key] == 'object') {
			flatAndSlugifyObj(obj[key], propName, res);
		} else {
			res[propName] = obj[key] as unknown as string;
		}
	}
	return res;
}

/**
 * Create a snake_case of the given text
 * @param text
 */
export function toSnakeCase(text: string): string {
	return text
		.replace(/\W+/g, ' ')
		.split(/ |\B(?=[A-Z])/)
		.map((word) => word.toLowerCase())
		.join('_');
}

export function numberToPixels(spacing: number): PIX {
	return `${+spacing}px`;
}

export const slugify = (str: string) =>
	str
		.toLowerCase()
		.trim()
		.replace(/[^\w\s-]/g, '')
		.replace(/[\s_-]+/g, '-')
		.replace(/^-+|-+$/g, '');

export const targetAudienceMap: Record<ThemeNames, string> = {
	maia: 'ondernemers',
	nvm: 'NVM-makelaars en taxateurs',
	vbo: 'VBO-makelaars en taxateurs',
	vastgoedpro: 'Vastgoedpro-makelaars, taxateurs en bouwkundig keurders',
	onderhoudnl: 'schilders en onderhoudsspecialisten',
	anko: 'kappers',
	cybermeister: 'cybersecurity-specialisten',
	meestersgilde: 'vaklieden',
	trustoo: 'ondernemers',
	deonlinebeautycursussen: 'Beauty specialisten',
	nkc: 'NKC',
	marengo: 'Marengo',
};

export const claimIconMap: Record<string, string> = {
	claim_date: 'schedule',
	insurance_guid: 'file-shield-alt',
	industrial_classification_guid: 'no-entry',
	detailed_cause_guid: 'crosshair-alt',
	detailed_cause_description: 'crosshair-alt',
	claimed_amount: 'euro-circle',
	subject: 'brain',
};

export const readableAddress = (address: Address) =>
	`${address?.street ?? ''} ${address?.house_number ?? ''}${
		address?.house_number_appendix ? ` ${address?.house_number_appendix}` : ''
	}, ${address?.postal_code ?? ''} ${address?.city ?? ''}`;
