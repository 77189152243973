const ROOTS = {
	CONSUMER: '/particulier',
	FUNNEL: '/aanvragen',
	FUNNEL_COMPANY: '/bedrijf',
	FUNNEL_PROFILE: '/profiel',
	FUNNEL_COMPOSE: '/samenstellen',
	FUNNEL_PRECONDITIONS: '/acceptatie',
	FUNNEL_FINALIZE: '/afronden',
	FUNNEL_CONCLUSION: '/slot',
	FUNNEL_ONBOARDING: '/onboarding',
	COMPLIANCY: '/compliancy',
	COMPLIANCY_UBO: '/ubo',
	MARKETING: '/marketing',
	MARKETING_ABOUT: '/over-ons',
	MARKETING_ADVICE: '/advies',
	MARKETING_CONTACT: '/contact',
	MARKETING_CLAIMS: '/claims',
	MARKETING_FAQ: '/veelgestelde-vragen',
	MARKETING_INSURANCES: '/verzekeringen',
	MARKETING_NICHES: '/werkvelden',
	MARKETING_PARTNERS: '/partners',
	MARKETING_PRESS: '/pers',
	PORTAL: '/portaal',
	PORTAL_DASHBOARD: '/overzicht',
	PORTAL_INSURANCES: '/verzekeringen',
	PORTAL_CHECKUP: '/checkup',
	PORTAL_INSURANCES_NEW: '/nieuwe-verzekering',
	PORTAL_INSURANCES_NEW_PROFILE: '/profiel',
	PORTAL_INSURANCES_NEW_COMPOSE: '/samenstellen',
	PORTAL_INSURANCES_NEW_PRECONDITIONS: '/acceptatie',
	PORTAL_INSURANCES_NEW_FINALIZE: '/afronden',
	PORTAL_INSURANCES_NEW_CONCLUSION: '/slot',
	PORTAL_CLAIMS: '/claims',
	PORTAL_CLAIMS_NEW: '/nieuwe-claim',
	PORTAL_TASKS: '/taken',
	PORTAL_INVOICES: '/facturen',
	PORTAL_COMPANY: '/bedrijf',
	PORTAL_NEWS: '/nieuws',
	PORTAL_ACCOUNT: '/account',
	PORTAL_FAQ: '/veelgestelde-vragen',
	CONTENT: '/content',
	ADMIN: '/superadmin',
	ADMIN_USERS: '/gebruikers',
	ADMIN_AI: '/ai',
	ADMIN_AI_MODELS: '/models',
	ADMIN_AI_FILES: '/files',
	ADMIN_AI_ASSISTANTS: '/assistants',
} as const;

const CONSUMER_FUNNEL_PATHS = {
	root: (product: string) => `${ROOTS.CONSUMER}/${product}${ROOTS.FUNNEL}`,
	premiumQuestions: {
		root: `${ROOTS.CONSUMER}${ROOTS.FUNNEL}${ROOTS.FUNNEL_PROFILE}`,
		petName: `${ROOTS.CONSUMER}${ROOTS.FUNNEL}${ROOTS.FUNNEL_PROFILE}/pet-name`,
		petCharacteristics: `${ROOTS.CONSUMER}${ROOTS.FUNNEL}${ROOTS.FUNNEL_PROFILE}/pet-characteristics`,
		petBreed: `${ROOTS.CONSUMER}${ROOTS.FUNNEL}${ROOTS.FUNNEL_PROFILE}/pet-breed`,
		petNeuterd: `${ROOTS.CONSUMER}${ROOTS.FUNNEL}${ROOTS.FUNNEL_PROFILE}/pet-neutered`,
		petSterilized: `${ROOTS.CONSUMER}${ROOTS.FUNNEL}${ROOTS.FUNNEL_PROFILE}/pet-sterilized`,
		dogSize: `${ROOTS.CONSUMER}${ROOTS.FUNNEL}${ROOTS.FUNNEL_PROFILE}/dog-size`,
		petIndoorOutdoor: `${ROOTS.CONSUMER}${ROOTS.FUNNEL}${ROOTS.FUNNEL_PROFILE}/pet-indoor-outdoor`,
		objectDetails: `${ROOTS.CONSUMER}${ROOTS.FUNNEL}${ROOTS.FUNNEL_PROFILE}/object-details`,
		mainDriver: `${ROOTS.CONSUMER}${ROOTS.FUNNEL}${ROOTS.FUNNEL_PROFILE}/main-driver`,
		ownerShip: `${ROOTS.CONSUMER}${ROOTS.FUNNEL}${ROOTS.FUNNEL_PROFILE}/object-ownership`,
		driverExperience: `${ROOTS.CONSUMER}${ROOTS.FUNNEL}${ROOTS.FUNNEL_PROFILE}/driver-experience`,
		consumerAffinityGroup: `${ROOTS.CONSUMER}${ROOTS.FUNNEL}${ROOTS.FUNNEL_PROFILE}/consumer-affinitygroup`,
		boughtInTheNetherlands: `${ROOTS.CONSUMER}${ROOTS.FUNNEL}${ROOTS.FUNNEL_PROFILE}/bought-in-netherlands`,
		dynamic: (page: string) => `${ROOTS.CONSUMER}${ROOTS.FUNNEL}${ROOTS.FUNNEL_PROFILE}/${page}`,
	},
	compose: {
		root: `${ROOTS.CONSUMER}${ROOTS.FUNNEL}${ROOTS.FUNNEL_COMPOSE}`,
		configure: `${ROOTS.CONSUMER}${ROOTS.FUNNEL}${ROOTS.FUNNEL_COMPOSE}/configureren`,
		extraMotor: `${ROOTS.CONSUMER}${ROOTS.FUNNEL}${ROOTS.FUNNEL_COMPOSE}/extra-motor`,
		addLicensePlate: `${ROOTS.CONSUMER}${ROOTS.FUNNEL}${ROOTS.FUNNEL_COMPOSE}/kenteken-toevoegen`,
		optionalProducts: `${ROOTS.CONSUMER}${ROOTS.FUNNEL}${ROOTS.FUNNEL_COMPOSE}/optionele-producten`,
		overview: `${ROOTS.CONSUMER}${ROOTS.FUNNEL}${ROOTS.FUNNEL_COMPOSE}/overzicht`,
	},
	preconditions: {
		root: `${ROOTS.CONSUMER}${ROOTS.FUNNEL}${ROOTS.FUNNEL_PRECONDITIONS}`,
		acceptance_statement: `${ROOTS.CONSUMER}${ROOTS.FUNNEL}${ROOTS.FUNNEL_PRECONDITIONS}/acceptance-statement`,
		current_insurance: `${ROOTS.CONSUMER}${ROOTS.FUNNEL}${ROOTS.FUNNEL_PRECONDITIONS}/current-insurance`,
		cancel_current_insurance: `${ROOTS.CONSUMER}${ROOTS.FUNNEL}${ROOTS.FUNNEL_PRECONDITIONS}/cancel-current-insurance`,
		historical_claims: `${ROOTS.CONSUMER}${ROOTS.FUNNEL}${ROOTS.FUNNEL_PRECONDITIONS}/historical-claims`,
		claims_overview: `${ROOTS.CONSUMER}${ROOTS.FUNNEL}${ROOTS.FUNNEL_PRECONDITIONS}/claims-overview`,
		closing_statement: `${ROOTS.CONSUMER}${ROOTS.FUNNEL}${ROOTS.FUNNEL_PRECONDITIONS}/closing-statement`,
		dynamic: (page: string) => `${ROOTS.CONSUMER}${ROOTS.FUNNEL}${ROOTS.FUNNEL_PRECONDITIONS}/${page}`,
	},
	finalize: {
		root: `${ROOTS.CONSUMER}${ROOTS.FUNNEL}${ROOTS.FUNNEL_FINALIZE}`,
		contactDetails: `${ROOTS.CONSUMER}${ROOTS.FUNNEL}${ROOTS.FUNNEL_FINALIZE}/contactgegevens`,
		summary: `${ROOTS.CONSUMER}${ROOTS.FUNNEL}${ROOTS.FUNNEL_FINALIZE}/samenvatting`,
		request_contact: `${ROOTS.CONSUMER}${ROOTS.FUNNEL}${ROOTS.FUNNEL_FINALIZE}/contactgegevens-invullen`,
	},
	conclusion: {
		root: `${ROOTS.CONSUMER}${ROOTS.FUNNEL}${ROOTS.FUNNEL_CONCLUSION}`,
		approved_after_review: `${ROOTS.CONSUMER}${ROOTS.FUNNEL}${ROOTS.FUNNEL_CONCLUSION}/goedgekeurd-na-review`,
		requested_contact: `${ROOTS.CONSUMER}${ROOTS.FUNNEL}${ROOTS.FUNNEL_CONCLUSION}/contactgegevens-verzonden`,
	},
} as const;

const FUNNEL_PATHS = {
	root: `${ROOTS.FUNNEL}`,
	real_estate_agents: `${ROOTS.FUNNEL}/makelaars`,
	company: {
		root: `${ROOTS.FUNNEL}${ROOTS.FUNNEL_COMPANY}`,
		add_data: `${ROOTS.FUNNEL}${ROOTS.FUNNEL_COMPANY}/gegevens-toevoegen`,
		check: `${ROOTS.FUNNEL}${ROOTS.FUNNEL_COMPANY}/controleren`,
		confirm: `${ROOTS.FUNNEL}${ROOTS.FUNNEL_COMPANY}/bevestigen`,
		search: `${ROOTS.FUNNEL}${ROOTS.FUNNEL_COMPANY}/zoeken`,
		login: `${ROOTS.FUNNEL}${ROOTS.FUNNEL_COMPANY}/inloggen`,
	},
	profile: {
		root: `${ROOTS.FUNNEL}${ROOTS.FUNNEL_PROFILE}`,
		activities: `${ROOTS.FUNNEL}${ROOTS.FUNNEL_PROFILE}/activiteiten`,
		dynamic: (page: string) => `${ROOTS.FUNNEL}${ROOTS.FUNNEL_PROFILE}/${page}`,
	},
	compose: {
		root: `${ROOTS.FUNNEL}${ROOTS.FUNNEL_COMPOSE}`,
		bundles: `${ROOTS.FUNNEL}${ROOTS.FUNNEL_COMPOSE}/bundels`,
		configure_bundle: `${ROOTS.FUNNEL}${ROOTS.FUNNEL_COMPOSE}/bundel-configureren`,
		optional_products: `${ROOTS.FUNNEL}${ROOTS.FUNNEL_COMPOSE}/optionele-producten`,
		overview: `${ROOTS.FUNNEL}${ROOTS.FUNNEL_COMPOSE}/overzicht`,
		rider_products: `${ROOTS.FUNNEL}${ROOTS.FUNNEL_COMPOSE}/extra-producten`,
	},
	// Keep english
	preconditions: {
		root: `${ROOTS.FUNNEL}${ROOTS.FUNNEL_PRECONDITIONS}`,
		acceptance_statement: `${ROOTS.FUNNEL}${ROOTS.FUNNEL_PRECONDITIONS}/acceptance-statement`,
		cancel_current_insurance: `${ROOTS.FUNNEL}${ROOTS.FUNNEL_PRECONDITIONS}/cancel-current-insurance`,
		claims_overview: `${ROOTS.FUNNEL}${ROOTS.FUNNEL_PRECONDITIONS}/claims-overview`,
		closing_statement: `${ROOTS.FUNNEL}${ROOTS.FUNNEL_PRECONDITIONS}/closing-statement`,
		dynamic: (precondition: string) => `${ROOTS.FUNNEL}${ROOTS.FUNNEL_PRECONDITIONS}/${precondition}`,
	},
	finalize: {
		root: `${ROOTS.FUNNEL}${ROOTS.FUNNEL_FINALIZE}`,
		contact_details: `${ROOTS.FUNNEL}${ROOTS.FUNNEL_FINALIZE}/contactgegevens`,
		summary: `${ROOTS.FUNNEL}${ROOTS.FUNNEL_FINALIZE}/samenvatting`,
		request_contact: `${ROOTS.FUNNEL}${ROOTS.FUNNEL_FINALIZE}/contactgegevens-invullen`,
	},
	conclusion: {
		root: `${ROOTS.FUNNEL}${ROOTS.FUNNEL_CONCLUSION}`,
		approved_after_review: `${ROOTS.FUNNEL}${ROOTS.FUNNEL_CONCLUSION}/goedgekeurd-na-review`,
		requested_contact: `${ROOTS.FUNNEL}${ROOTS.FUNNEL_CONCLUSION}/contactgegevens-verzonden`,
	},
} as const;

const PORTAL_PATHS = {
	root: `${ROOTS.PORTAL}`,
	dashboard: {
		root: `${ROOTS.PORTAL}${ROOTS.PORTAL_DASHBOARD}`,
	},
	onboarding: {
		root: `${ROOTS.PORTAL}${ROOTS.FUNNEL_ONBOARDING}`,
	},
	checkup: {
		root: (guid: string) => `${ROOTS.PORTAL}${ROOTS.PORTAL_CHECKUP}/${guid}`,
		dynamic: (guid: string, slug: string) => `${ROOTS.PORTAL}${ROOTS.PORTAL_CHECKUP}/${guid}/${slug.toLowerCase()}`,
		activities: {
			root: (guid: string) => `${ROOTS.PORTAL}${ROOTS.PORTAL_CHECKUP}/${guid}/activiteiten`,
			edit: (guid: string) => `${ROOTS.PORTAL}${ROOTS.PORTAL_CHECKUP}/${guid}/activiteiten/aanpassen`,
		},
		address: {
			root: (guid: string) => `${ROOTS.PORTAL}${ROOTS.PORTAL_CHECKUP}/${guid}/adressen`,
			edit: {
				root: (guid: string) => `${ROOTS.PORTAL}${ROOTS.PORTAL_CHECKUP}/${guid}/adressen/aanpassen`,
				dynamic: (guid: string, addressGuid: string) =>
					`${ROOTS.PORTAL}${ROOTS.PORTAL_CHECKUP}/${guid}/adressen/aanpassen/${addressGuid}`,
			},
		},
		fte: {
			root: (guid: string) => `${ROOTS.PORTAL}${ROOTS.PORTAL_CHECKUP}/${guid}/fte`,
			edit: {
				root: (guid: string) => `${ROOTS.PORTAL}${ROOTS.PORTAL_CHECKUP}/${guid}/fte/aanpassen`,
			},
		},
		revenue: {
			root: (guid: string) => `${ROOTS.PORTAL}${ROOTS.PORTAL_CHECKUP}/${guid}/revenue`,
		},
		lumpsum: {
			root: (guid: string) => `${ROOTS.PORTAL}${ROOTS.PORTAL_CHECKUP}/${guid}/lumpsum`,
		},
		acceptanceStatements: {
			root: (guid: string) => `${ROOTS.PORTAL}${ROOTS.PORTAL_CHECKUP}/${guid}/verklaringen`,
		},
		cyberAcceptanceStatements: {
			root: (guid: string) => `${ROOTS.PORTAL}${ROOTS.PORTAL_CHECKUP}/${guid}/cyber`,
		},
		finalize: {
			root: (guid: string) => `${ROOTS.PORTAL}${ROOTS.PORTAL_CHECKUP}/${guid}/afronden`,
		},
		finished: {
			root: (guid: string) => `${ROOTS.PORTAL}${ROOTS.PORTAL_CHECKUP}/${guid}/gelukt`,
		},
	},
	insurances: {
		root: `${ROOTS.PORTAL}${ROOTS.PORTAL_INSURANCES}`,
		details: (guid: string) => `${ROOTS.PORTAL}${ROOTS.PORTAL_INSURANCES}/${guid}`,
		new: {
			root: `${ROOTS.PORTAL}${ROOTS.PORTAL_INSURANCES}${ROOTS.PORTAL_INSURANCES_NEW}`,
			summary: `${ROOTS.PORTAL}${ROOTS.PORTAL_INSURANCES}${ROOTS.PORTAL_INSURANCES_NEW}/samenvatting`,
			profile: {
				root: `${ROOTS.PORTAL}${ROOTS.PORTAL_INSURANCES}${ROOTS.PORTAL_INSURANCES_NEW}${ROOTS.PORTAL_INSURANCES_NEW_PROFILE}`,
				dynamic: (page: string) =>
					`${ROOTS.PORTAL}${ROOTS.PORTAL_INSURANCES}${ROOTS.PORTAL_INSURANCES_NEW}${ROOTS.PORTAL_INSURANCES_NEW_PROFILE}/${page}`,
			},
			compose: {
				root: `${ROOTS.PORTAL}${ROOTS.PORTAL_INSURANCES}${ROOTS.PORTAL_INSURANCES_NEW}${ROOTS.PORTAL_INSURANCES_NEW_COMPOSE}`,
				configure: `${ROOTS.PORTAL}${ROOTS.PORTAL_INSURANCES}${ROOTS.PORTAL_INSURANCES_NEW}${ROOTS.PORTAL_INSURANCES_NEW_COMPOSE}/configureren`,
			},
			conclusion: {
				root: `${ROOTS.PORTAL}${ROOTS.PORTAL_INSURANCES}${ROOTS.PORTAL_INSURANCES_NEW}${ROOTS.PORTAL_INSURANCES_NEW_CONCLUSION}`,
				requested_contact: `${ROOTS.PORTAL}${ROOTS.PORTAL_INSURANCES}${ROOTS.PORTAL_INSURANCES_NEW}${ROOTS.PORTAL_INSURANCES_NEW_CONCLUSION}/contactgegevens-verzonden`,
			},
			// Keep english
			preconditions: {
				root: `${ROOTS.PORTAL}${ROOTS.PORTAL_INSURANCES}${ROOTS.PORTAL_INSURANCES_NEW}${ROOTS.PORTAL_INSURANCES_NEW_PRECONDITIONS}`,
				acceptance_statement: `${ROOTS.PORTAL}${ROOTS.PORTAL_INSURANCES}${ROOTS.PORTAL_INSURANCES_NEW}${ROOTS.PORTAL_INSURANCES_NEW_PRECONDITIONS}/acceptance-statement`,
				closing_statement: `${ROOTS.PORTAL}${ROOTS.PORTAL_INSURANCES}${ROOTS.PORTAL_INSURANCES_NEW}${ROOTS.PORTAL_INSURANCES_NEW_PRECONDITIONS}/closing-statement`,
				claims_overview: `${ROOTS.PORTAL}${ROOTS.PORTAL_INSURANCES}${ROOTS.PORTAL_INSURANCES_NEW}${ROOTS.PORTAL_INSURANCES_NEW_PRECONDITIONS}/claims-overview`,
				cancel_current_insurance: `${ROOTS.PORTAL}${ROOTS.PORTAL_INSURANCES}${ROOTS.PORTAL_INSURANCES_NEW}${ROOTS.PORTAL_INSURANCES_NEW_PRECONDITIONS}/cancel-current-insurance`,
				dynamic: (precondition: string) =>
					`${ROOTS.PORTAL}${ROOTS.PORTAL_INSURANCES}${ROOTS.PORTAL_INSURANCES_NEW}${ROOTS.PORTAL_INSURANCES_NEW_PRECONDITIONS}/${precondition}`,
			},
			finalize: {
				root: `${ROOTS.PORTAL}${ROOTS.PORTAL_INSURANCES}${ROOTS.PORTAL_INSURANCES_NEW}${ROOTS.PORTAL_INSURANCES_NEW_FINALIZE}`,
				request_contact: `${ROOTS.PORTAL}${ROOTS.PORTAL_INSURANCES}${ROOTS.PORTAL_INSURANCES_NEW}${ROOTS.PORTAL_INSURANCES_NEW_FINALIZE}/contactgegevens-invullen`,
				summary: `${ROOTS.PORTAL}${ROOTS.PORTAL_INSURANCES}${ROOTS.PORTAL_INSURANCES_NEW}${ROOTS.PORTAL_INSURANCES_NEW_FINALIZE}/samenvatting`,
			},
		},
	},
	claims: {
		root: `${ROOTS.PORTAL}${ROOTS.PORTAL_CLAIMS}`,
		new: {
			root: `${ROOTS.PORTAL}${ROOTS.PORTAL_CLAIMS}/${ROOTS.PORTAL_CLAIMS_NEW}`,
			finish: (guid: string) => `${ROOTS.PORTAL}${ROOTS.PORTAL_CLAIMS}/${ROOTS.PORTAL_CLAIMS_NEW}/${guid}/afronden`,
			success: (guid: string) => `${ROOTS.PORTAL}${ROOTS.PORTAL_CLAIMS}/${ROOTS.PORTAL_CLAIMS_NEW}/${guid}/gelukt`,
		},
		details: (guid: string) => `${ROOTS.PORTAL}${ROOTS.PORTAL_CLAIMS}/${guid}`,
		chat: (guid: string) => `${ROOTS.PORTAL}${ROOTS.PORTAL_CLAIMS}/${guid}/chat`,
	},
	tasks: {
		root: `${ROOTS.PORTAL}${ROOTS.PORTAL_TASKS}`,
	},
	invoices: {
		root: `${ROOTS.PORTAL}${ROOTS.PORTAL_INVOICES}`,
	},
	news: {
		root: `${ROOTS.PORTAL}${ROOTS.PORTAL_NEWS}`,
		details: (guid: string) => `${ROOTS.PORTAL}${ROOTS.PORTAL_NEWS}/${guid}`,
	},
	company: {
		root: `${ROOTS.PORTAL}${ROOTS.PORTAL_COMPANY}`,
	},
	account: {
		root: `${ROOTS.PORTAL}${ROOTS.PORTAL_ACCOUNT}`,
	},
	faq: {
		root: `${ROOTS.PORTAL}${ROOTS.PORTAL_FAQ}`,
	},
} as const;

const COMPLIANCY_PATHS = {
	root: `${ROOTS.COMPLIANCY}`,
	ubo: {
		root: `${ROOTS.COMPLIANCY}${ROOTS.COMPLIANCY_UBO}`,
		conclusion: {
			root: (guid: string) => `${ROOTS.COMPLIANCY}${ROOTS.COMPLIANCY_UBO}/${guid}/gelukt`,
		},
	},
} as const;

const ADMIN_PATHS = {
	root: `${ROOTS.ADMIN}`,
	users: {
		root: `${ROOTS.ADMIN}${ROOTS.ADMIN_USERS}`,
		new: `${ROOTS.ADMIN}${ROOTS.ADMIN_USERS}/nieuwe-gebruiker`,
		details: (guid: string) => `${ROOTS.ADMIN}${ROOTS.ADMIN_USERS}/${guid}`,
	},
} as const;

const COMMON_PATHS = {
	root: '/',
	login: '/inloggen',
	logout: '/uitloggen',
	verify_email: '/email-verifieren',
	preview: '/preview',
} as const;

const MARKETING_PATHS = {
	root: `${ROOTS.MARKETING}`,
	about: {
		root: `${ROOTS.MARKETING}${ROOTS.MARKETING_ABOUT}`,
	},
	advice: {
		root: `${ROOTS.MARKETING}${ROOTS.MARKETING_ADVICE}`,
	},
	claims: {
		root: `${ROOTS.MARKETING}${ROOTS.MARKETING_CLAIMS}`,
		report_damage: `${ROOTS.MARKETING}${ROOTS.MARKETING_CLAIMS}/schade-melden`,
		customer_support: `${ROOTS.MARKETING}${ROOTS.MARKETING_CLAIMS}/klantenservice`,
	},
	contact: {
		root: `${ROOTS.MARKETING}${ROOTS.MARKETING_CONTACT}`,
	},
	faq: {
		root: `${ROOTS.MARKETING}${ROOTS.MARKETING_FAQ}`,
	},
	insurances: {
		root: `${ROOTS.MARKETING}${ROOTS.MARKETING_INSURANCES}`,
		detail: (slug: string) => `${ROOTS.MARKETING}${ROOTS.MARKETING_INSURANCES}/${slug}`,
	},
	niches: {
		root: `${ROOTS.MARKETING}${ROOTS.MARKETING_NICHES}`,
		detail: (slug: string) => `${ROOTS.MARKETING}${ROOTS.MARKETING_NICHES}/${slug}`,
		professions_detail: (nicheSlug: string, professionsSlug: string) =>
			`${ROOTS.MARKETING}${ROOTS.MARKETING_NICHES}/${nicheSlug}/${professionsSlug}`,
	},
	partners: {
		root: `${ROOTS.MARKETING}${ROOTS.MARKETING_PARTNERS}`,
	},
	press: {
		root: `${ROOTS.MARKETING}${ROOTS.MARKETING_PRESS}`,
	},
} as const;

const CONTENT_PATHS = {
	root: `${ROOTS.CONTENT}`,
	terms: `${ROOTS.CONTENT}/algemene-voorwaarden`,
	privacy_statement: `${ROOTS.CONTENT}/privacybeleid`,
	disclaimer: `${ROOTS.CONTENT}/disclaimer`,
	cookies: `${ROOTS.CONTENT}/cookies`,
} as const;

/* istanbul ignore next */
export const paths = {
	...COMMON_PATHS,
	admin: ADMIN_PATHS,
	funnel: FUNNEL_PATHS,
	consumerFunnel: CONSUMER_FUNNEL_PATHS,
	content: CONTENT_PATHS,
	compliancy: COMPLIANCY_PATHS,
	marketing: MARKETING_PATHS,
	portal: PORTAL_PATHS,
	fourofour: '/404',
} as const;
