import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { sortProducts } from '@monorepo/shared/lib/utils';
import { CartItem, InsuranceDocs, Organization, SupportedInsurance } from '@monorepo/types';

import { useNavigation } from '@common/hooks';
import {
	useGetProductQuestionsForObjectQuery,
	useGetProductStructureForCartQuery,
	useGetProductStructureForObjectQuery,
} from '@consumer/store';
import { useProducts } from '@funnel/hooks';
import {
	selectCalculationState,
	selectShoppingCartState,
	selectShownCalculationState,
	selectShownCalculationStateWithoutOptionalProducts,
	useGetCartOrganizationQuery,
	useGetInsuranceDocsQuery,
} from '@funnel/store';
import { mapContentToShoppingCartProduct } from '@funnel/utils';

// TODO: This hook should be restructured in its entierty, the amount of logic in this hook has become unmanagable
export const useShoppingCart = () => {
	const {
		pathname,
		query: { cartGuid, objectGuid },
	} = useRouter();
	const { t } = useTranslation(['common']);
	const { isB2BFunnel, isB2CFunnel } = useNavigation();
	const { data: organization = {} as Organization } = useGetCartOrganizationQuery(
		cartGuid && isB2BFunnel && !isB2CFunnel ? (cartGuid as string) : skipToken
	);

	const isConfigureBundle = pathname.includes('/configure-bundle') || pathname.includes('/bundel-configureren');
	const isConfigureOverview = pathname.includes('/overview') || pathname.includes('/overzicht');
	const isConfigureOptionalProducts =
		pathname.includes('/optional-products') || pathname.includes('/optionele-producten');

	const { bundles, isLoading, isFetching } = useProducts(`${cartGuid}`);

	const isFullVariant = pathname.includes('/compose') || pathname.includes('/samenstellen');

	const { calculations } = useSelector(
		isConfigureBundle ? selectShownCalculationStateWithoutOptionalProducts : selectShownCalculationState
	);

	const isConsumer = pathname.includes('/particulier');

	const allCalculations = useSelector(selectCalculationState);
	const { data: productStructureForObject = [], isLoading: productStructureForObjectIsLoading } =
		useGetProductStructureForObjectQuery(
			cartGuid && objectGuid && isConsumer
				? {
						cartGuid: cartGuid as string,
						objectGuid: objectGuid as string,
					}
				: skipToken
		);
	const { data: productStructure = [], isLoading: productStructureIsLoading } = useGetProductStructureForCartQuery(
		cartGuid && isConsumer
			? {
					cartGuid: cartGuid as string,
				}
			: skipToken
	);

	const { data: productQuestions = [], isLoading: productQuestionsLoading } = useGetProductQuestionsForObjectQuery(
		cartGuid && objectGuid && isConsumer
			? {
					cartGuid: cartGuid as string,
					objectGuid: objectGuid as string,
				}
			: skipToken
	);

	const { data: documents = {} as InsuranceDocs } = useGetInsuranceDocsQuery(
		cartGuid && (calculations.length > 0 || productStructure?.length > 0) ? (cartGuid as string) : skipToken
	);

	const shoppingCartState = useSelector(selectShoppingCartState);

	const isValid = useMemo(() => {
		if (isConsumer) {
			const activeProducts = productStructureForObject?.filter((product) => product.product.is_selected);

			return productQuestions
				.filter((product) => activeProducts.some(({ product: { guid } }) => guid === product.guid))
				.flatMap(({ product_questions }) => product_questions)
				.every(({ is_answered }) => is_answered);
		} else {
			const _calculations = calculations ? [...calculations] : [];
			const activeCalculations = _calculations.filter(({ product: { is_active } }) => is_active);

			// Check for products with preconditions
			const productsWithPreconditions = activeCalculations.filter(
				(calculation) => calculation.product.preconditions.length > 0
			);

			if (productsWithPreconditions.length > 0) {
				if (
					productsWithPreconditions.some(
						(calculation) =>
							calculation.product.preconditions.filter((precondition) => precondition.answer === null).length > 0
					)
				) {
					return false;
				}
			}

			// Check for products that are dependent but the dependency is not active
			const hasInactiveDependency = activeCalculations.some(({ product: { dependant_on } }) => {
				return dependant_on?.some((dependency) => {
					// Check if the dependent product is not active
					return !activeCalculations.some(({ product }) => product.guid === dependency.guid);
				});
			});

			if (hasInactiveDependency) {
				return false;
			}

			return activeCalculations.some(
				(calculation) =>
					calculation.product.sold_separately &&
					calculation.product.options.some((option) => option.is_active) &&
					calculation.product.options.every((option) => option.is_calculated)
			);
		}
	}, [calculations, isConsumer, productStructureForObject, productQuestions]);

	const cartItems: CartItem[] = useMemo(
		() =>
			isConsumer
				? productStructure
						// Only keep the calculations that are active
						?.filter((product) => product.product.is_selected)
						// Add the documents to the calculation
						.map((product) => {
							return {
								...product,
								documents: documents?.products?.find(
									({ composition }) => composition?.guid === product.product.composition.guid
								),
								// documents: documents.find(())
							};
						})
						// Clean object
						.map((product) => {
							const activeOption = product.product.options.find((option) => option.is_selected);
							const selectedOptions = product.product.options?.filter(({ is_selected }) => is_selected);

							return {
								guid: activeOption?.guid as string,
								name: product.product.name,
								brand: product.product.insurer,
								price: selectedOptions.reduce((sum, { price }) => sum + price.premium_after_tax, 0),
								promotion: activeOption?.price.promotion === 'first_year' ? `${activeOption?.price.promotion}` : false,
								icon: 'product.product.icon_name',
								// Add risk addresses other than the organization address
								...(product?.product?.risk_addresses &&
									product?.product?.risk_addresses.length > 0 && {
										risk_addresses: product.product.risk_addresses.filter(
											(address) =>
												address.postal_code !== organization?.primary_address?.postal_code &&
												address.house_number !== organization?.primary_address?.house_number
										),
									}),
								priceBeforeDiscount: activeOption?.price?.premium_before_discount,
								priceAfterDiscount: activeOption?.price?.premium_after_discount,
								tax: selectedOptions.reduce((sum, { price }) => sum + price.tax, 0),
								documents: product.documents,
								// items: mapContentToShoppingCartProduct({
								// 	t,
								// 	isConfigure: isConfigureBundle || isConfigureOverview,
								// 	calculation: product,
								// }),
								items: [],
							};
						})
						.sort((calculationA, calculationB) =>
							sortProducts(calculationA.name as SupportedInsurance, calculationB.name as SupportedInsurance)
						)
				: calculations
						// Only keep the calculations that are active
						?.filter((calculation) => calculation.product.is_active)
						// Filter out all the products that still have unanswered preconditions
						.filter((calculation) =>
							calculation.product.preconditions.length > 0
								? calculation.product.preconditions.every((precondition) => precondition.answer !== null)
								: true
						)
						// Filter out all the inactive product option
						.map((calculation) => {
							return {
								...calculation,
								product: {
									...calculation.product,
									options: calculation.product.options.filter((option) => {
										if (
											bundles?.flatMap(({ products }) => products).some(({ code }) => code === calculation.product.code)
										) {
											return option.is_active;
										}

										return option.is_active || option.name === 'Bedrijfsaansprakelijkheid';
									}),
								},
							};
						})
						// Add the documents to the calculation
						.map((calculation) => {
							return {
								...calculation,
								documents: documents?.products?.find(({ code }) => code === calculation.product.code),
							};
						})
						// Clean object
						.map((calculation) => {
							const activeOption = calculation.product.options.find((option) => option.is_active);

							return {
								guid: activeOption?.guid as string,
								name: calculation.product.name,
								brand: calculation.product.insurer,
								price: calculation.product.options?.reduce((sum, { price }) => sum + price.premium_after_tax, 0),
								promotion: activeOption?.price.promotion === 'first_year' ? `${activeOption?.price.promotion}` : false,
								icon: calculation.product.icon_name,
								// Add risk addresses other than the organization address
								...(calculation?.product?.risk_addresses &&
									calculation?.product?.risk_addresses.length > 0 && {
										risk_addresses: calculation.product.risk_addresses.filter(
											(address) =>
												address.postal_code !== organization?.primary_address?.postal_code &&
												address.house_number !== organization?.primary_address?.house_number
										),
									}),
								priceBeforeDiscount: activeOption?.price?.premium_before_discount,
								priceAfterDiscount: activeOption?.price?.premium_after_discount,
								tax: calculation.product.options?.reduce((sum, { price }) => sum + price.tax, 0),
								documents: calculation.documents,
								items: mapContentToShoppingCartProduct({
									t,
									isConfigure: isConfigureBundle || isConfigureOverview,
									calculation,
								}),
							};
						})
						.sort((calculationA, calculationB) =>
							sortProducts(calculationA.name as SupportedInsurance, calculationB.name as SupportedInsurance)
						),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[calculations, bundles, isConsumer, productStructure, documents]
	);

	const visibleItems: CartItem[] = useMemo(
		() =>
			calculations
				?.map((calculation) => {
					return {
						...calculation,
						product: {
							...calculation.product,
							options: calculation.product.options.filter((option) => {
								if (bundles?.some(({ code }) => code === calculation.product.code)) {
									return option.is_active;
								}

								return option.is_active || option.name === 'Bedrijfsaansprakelijkheid';
							}),
						},
					};
				})
				?.map((calculation) => {
					const activeOption = calculation.product.options.find((option) => option.is_active);

					return {
						guid: activeOption?.guid as string,
						name: calculation.product.name,
						brand: calculation.product.insurer,
						price: calculation.product.options?.reduce((sum, { price }) => sum + price.premium_after_tax, 0),
						promotion: activeOption?.price.promotion === 'first_year' ? `${activeOption?.price.promotion}` : false,
						icon: calculation.product.icon_name,
						// Add risk addresses other than the organization address
						...(calculation.product.risk_addresses && {
							risk_addresses: calculation.product.risk_addresses.filter(
								(address) =>
									address.postal_code !== organization?.primary_address?.postal_code &&
									address.house_number !== organization?.primary_address?.house_number
							),
						}),
						priceBeforeDiscount: activeOption?.price.premium_before_discount,
						priceAfterDiscount: activeOption?.price.premium_after_discount,
						tax: calculation.product.options?.reduce((sum, { price }) => sum + price.tax, 0),
						items: mapContentToShoppingCartProduct({
							t,
							isConfigure: isConfigureBundle || isConfigureOverview,
							calculation,
						}),
					};
				})
				.sort((calculationA, calculationB) =>
					sortProducts(calculationA.name as SupportedInsurance, calculationB.name as SupportedInsurance)
				),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[calculations, bundles]
	);

	const allAvailableItems: CartItem[] = useMemo(
		() =>
			allCalculations
				?.map((calculation) => {
					return {
						guid: calculation.product?.guid as string,
						name: calculation.product.name,
						brand: calculation.product.insurer,
						price: calculation.product.options?.reduce((sum, { price }) => sum + price.premium_after_tax, 0),

						icon: calculation.product.icon_name,
						// Add risk addresses other than the organization address
						...(calculation.product.risk_addresses && {
							risk_addresses: calculation.product.risk_addresses.filter(
								(address) =>
									address.postal_code !== organization?.primary_address?.postal_code &&
									address.house_number !== organization?.primary_address?.house_number
							),
						}),
						priceBeforeDiscount: Number(calculation.product.minimum_price),
						priceAfterDiscount: Number(calculation.product.minimum_price),
						tax: calculation.product.options?.reduce((sum, { price }) => sum + price.tax, 0),
						items: mapContentToShoppingCartProduct({
							t,
							isConfigure: isConfigureBundle || isConfigureOverview,
							calculation,
						}),
					};
				})
				.sort((calculationA, calculationB) =>
					sortProducts(calculationA.name as SupportedInsurance, calculationB.name as SupportedInsurance)
				),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[calculations]
	);

	return {
		isValid: isValid && shoppingCartState.isValid,
		isCalculating: shoppingCartState.isCalculating || isLoading || isFetching,
		loadingProducts: shoppingCartState.productIsLoading,
		isBundle: isConfigureBundle || isConfigureOverview || isConfigureOptionalProducts,
		isConsumer: isConsumer,
		customFooterText: isConsumer
			? t('common.next')
			: isConfigureOverview
				? t('common.finalize')
				: isConfigureBundle || isConfigureOptionalProducts
					? t('common.next')
					: undefined,
		isFullVariant,
		cartItems: cartItems || [],
		visibleItems,
		allAvailableItems,
		cartIsLoading:
			isLoading ||
			isFetching ||
			productStructureForObjectIsLoading ||
			productQuestionsLoading ||
			productStructureIsLoading,
		hasCartItems: cartItems && cartItems.length > 0,
	};
};
