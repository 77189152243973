import { type FC, type ReactNode } from 'react';
import { Provider } from 'react-redux';

import { FirebaseAuthProvider, FirebaseProvider } from '@common/providers';
import { makeStore, setPlatformState } from '@common/store';
import { setIdToken } from '@portal/store';

export const jestStore = makeStore();

type WrapperProps = {
	children: ReactNode;
};

/**
 * Wrapper component which provides all necessary functionality
 */
export const JestWrapper: FC<WrapperProps> = ({ children }) => {
	return (
		<>
			<Provider store={jestStore}>
				<FirebaseProvider>
					<FirebaseAuthProvider>{children}</FirebaseAuthProvider>
				</FirebaseProvider>
			</Provider>
			<div id='modals' />
		</>
	);
};

// Wrapper for tests
export const TestWrapper = ({ children }: { children: ReactNode }) => <JestWrapper>{children}</JestWrapper>;

export const jestPortalMiddleware = () => {
	// Sets a fake organization in our global state
	// jestStore.dispatch(setOrganization(mockPortalOrganizationOld()));
	// Sets a fake IDToken in our global state
	jestStore.dispatch(setIdToken('supersecret_jest_idtoken'));
	// Sets the platform protected state to true
	jestStore.dispatch(setPlatformState(true));
};

/**
 * Delete cookies
 */
export function deleteAllCookies() {
	const cookies = document.cookie.split(';');

	for (let i = 0; i < cookies.length; i++) {
		const cookie = cookies[i];
		const eqPos = cookie.indexOf('=');
		const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
		document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
	}
}

export async function waitForExpect(expectationFunction: () => Promise<void> | void, timeout = 3000, interval = 100) {
	const endTime = Date.now() + timeout;

	return new Promise((resolve, reject) => {
		async function runExpectation() {
			if (Date.now() > endTime) {
				reject(new Error('Timed out while waiting for expectation to be met.'));
				return;
			}

			try {
				await expectationFunction();
				resolve(true);
			} catch (error) {
				setTimeout(runExpectation, interval);
			}
		}

		setTimeout(runExpectation, 0);
	});
}
