import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState, hydrate } from '@common/store';

export type ShoppingCartState = {
	isCalculating: boolean;
	isValid: boolean;
	productIsLoading: Record<string, boolean>;
};

// Initial state
const initialState: ShoppingCartState = {
	isCalculating: false,
	isValid: true,
	productIsLoading: {},
};

// Actual Slice
export const shoppingCartSlice = createSlice({
	name: 'shoppingCart',
	initialState,
	reducers: {
		setCalculatingCart(state, action: PayloadAction<boolean>) {
			state.isCalculating = action.payload;
		},
		updateIsValidShoppingCart(state, action: PayloadAction<boolean>) {
			state.isValid = action.payload;
		},
		updateIsProductLoading(state, action: PayloadAction<{ guid: string; isLoading: boolean }>) {
			state.productIsLoading[action.payload.guid] = action.payload.isLoading;
		},
	},
	// Special reducer for hydrating the state. Special case for next-redux-wrapper
	extraReducers: (builder) => {
		builder.addCase(hydrate, (state, action: PayloadAction<{ shoppingCart: ShoppingCartState }>) => {
			return {
				...state,
				...action.payload.shoppingCart,
			};
		});
	},
});

export const { setCalculatingCart, updateIsValidShoppingCart, updateIsProductLoading } = shoppingCartSlice.actions;

export const selectShoppingCartState = (state: AppState) => state.shoppingCart;
export const selectShoppingCartCalculatingState = (state: AppState) => state.shoppingCart.isCalculating;
