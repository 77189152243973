import cn from 'classnames';
import { FC } from 'react';

import { BaseCard } from '@monorepo/components/DataDisplay';
import { Icon, LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { IInsuredSection } from '@monorepo/types';

type AddonSectionProps = IInsuredSection & {
	className?: string;
};

export const InsuredSection: FC<AddonSectionProps> = ({ title, subtitle, insured, className }) => {
	return (
		<section className={cn(className)}>
			<LayoutGrid layout='marketing'>
				<div className='col-start-2 flex flex-col space-y-48'>
					<div className='flex flex-col space-y-12'>
						<Text variant='display-4' weight='semibold' color='primaryMain'>
							{title}
						</Text>
						<Text variant='display-6' weight='regular' color='primaryMain'>
							{subtitle}
						</Text>
					</div>
					<div className='grid grid-cols-1 gap-48 align-top md:grid-cols-2'>
						<BaseCard
							as='div'
							className='bg-grayscale0 col-span-1 flex h-fit flex-col gap-y-24 p-24'
							enableEffects={false}>
							<div className='flex flex-row items-center gap-x-8'>
								<Icon name='check' size='xl' color='success300' />
								<Text variant='display-5' weight='semibold' color='grayscale600'>
									Wel verzekerd
								</Text>
							</div>
							<div className='flex flex-col gap-y-12'>
								<Text variant='body-s' weight='regular' color='grayscale400'>
									Hiervoor ben je <u>wel verzekerd</u>:
								</Text>
								<ul className='flex flex-col gap-y-12'>
									{insured
										.filter((_) => _.isInsured)
										.map((item) => (
											<li key={`insured-item-${item.title}`} className='flex flex-row items-center gap-x-8'>
												<Icon name={item.icon} size='md' color='primaryMain' />
												<Text variant='body-xs' weight='regular' color='grayscale600'>
													{item.title}
												</Text>
											</li>
										))}
								</ul>
							</div>
						</BaseCard>
						<BaseCard
							as='div'
							className='bg-grayscale0 col-span-1 flex h-fit flex-col gap-y-24 p-24'
							enableEffects={false}>
							<div className='flex flex-row items-center gap-x-8'>
								<Icon name='times' size='xl' color='error300' />
								<Text variant='display-5' weight='semibold' color='grayscale600'>
									Niet verzekerd
								</Text>
							</div>
							<div className='flex flex-col gap-y-12'>
								<Text variant='body-s' weight='regular' color='grayscale400'>
									Hiervoor ben je <u>niet verzekerd</u>:
								</Text>
								<ul className='flex flex-col gap-y-12'>
									{insured
										.filter((_) => !_.isInsured)
										.map((item) => (
											<li key={`unsured-item-${item.title}`} className='flex flex-row items-center gap-x-8'>
												<Icon name={item.icon} size='md' color='primaryMain' />
												<Text variant='body-xs' weight='regular' color='grayscale600'>
													{item.title}
												</Text>
											</li>
										))}
								</ul>
							</div>
						</BaseCard>
					</div>
				</div>
			</LayoutGrid>
		</section>
	);
};
