import cn from 'classnames';
import { ChangeEvent, FC, ReactNode } from 'react';
import ReactToggle from 'react-toggle';

import { TooltipIcon } from '../../../DataDisplay';
import { Icon } from '../../../Misc';
import { Text } from '../../../TextComponents';

type ToggleVariant = 'default' | 'onOff';

type ToggleProps = {
	isToggled: boolean;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void; // Fix is here.
	isDisabled?: boolean;
	isLoading?: boolean;
	variant?: ToggleVariant;
	tooltipId?: string;
	label?: string;
	tag?: ReactNode;
	className?: string;
};

const ToggleUnchecked = () => {
	return <Icon name='times' color='inherit' size='xxs' className='leading-none' />;
};

const ToggleChecked = () => {
	return <Icon name='check' color='inherit' size='xxs' className='leading-none' />;
};

export const Toggle: FC<ToggleProps> = ({
	isToggled = false,
	isDisabled = false,
	isLoading = false,
	onChange,
	label,
	tooltipId,
	tag,
	variant = 'default',
	className = '',
	...rest
}) => {
	const hasLabelOrTag = label || tag;

	const pointerClass =
		isDisabled && isLoading ? '!cursor-progress' : isDisabled && !isLoading ? '!cursor-not-allowed' : '!cursor-pointer';
	return (
		<label className={cn('z-50 flex flex-row items-start space-x-12 sm:items-center', pointerClass)} {...rest}>
			<ReactToggle
				tabIndex={0}
				icons={variant === 'onOff' ? { checked: <ToggleChecked />, unchecked: <ToggleUnchecked /> } : false}
				checked={isToggled}
				disabled={isDisabled}
				onChange={!isDisabled ? onChange : undefined}
				className={cn(
					className,
					`maia-insurance-product-toggle-${variant}`,
					'maia-insurance-product-toggle',
					pointerClass
				)}
			/>

			{hasLabelOrTag && (
				<div className='flex flex-col items-center max-sm:space-y-8 sm:grow sm:flex-row'>
					{label && (
						<Text className='sm:flex-1' variant='body-s' color={isDisabled ? 'grayscale400' : 'grayscale500'}>
							{label}
						</Text>
					)}
					{tag && tag}
				</div>
			)}

			{tooltipId && <TooltipIcon className='!ml-auto pl-8' id={tooltipId} iconName='info-circle' iconColor='info300' />}
		</label>
	);
};
