import { skipToken } from '@reduxjs/toolkit/query';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';

import { toAnswerRequestObject } from '@monorepo/shared/lib/utils';
import { AcceptanceQuestion, AnswerQuestionsRequest } from '@monorepo/types';

import { useNavigation } from '@common/hooks';
import { setNavForward } from '@common/store';
import { useAnswerConsumerAcceptanceQuestionsMutation, useGetConsumerAcceptanceQuestionsQuery } from '@consumer/store';
import { useQuestionPageManager } from '@funnel/hooks';
import { useAnswerAcceptanceQuestionsMutation, useGetAcceptanceQuestionsQuery } from '@funnel/store';

export const useAcceptanceQuestions = () => {
	const {
		push,
		query: { cartGuid, slug, objectGuid },
	} = useRouter();

	const { isB2BFunnel } = useNavigation();
	const accQuestionsQuery = isB2BFunnel ? useGetAcceptanceQuestionsQuery : useGetConsumerAcceptanceQuestionsQuery;
	const accQuestionsMutation = isB2BFunnel
		? useAnswerAcceptanceQuestionsMutation
		: useAnswerConsumerAcceptanceQuestionsMutation;

	const dispatch = useDispatch();

	const { data: acceptanceQuestions = [] as AcceptanceQuestion[], isSuccess } = accQuestionsQuery(
		(cartGuid as string) ?? skipToken
	);

	const questions = acceptanceQuestions?.filter(({ reference: lookupRef }) => lookupRef === slug);

	const { nextPage } = useQuestionPageManager({ isAcceptanceQuestions: true });
	const [answerAcceptanceQuestion, answerAcceptanceQuestionResponse] = accQuestionsMutation();

	const mapToAnswer = (answer: number | number[] | string | string[]): AnswerQuestionsRequest => {
		const answerArray = Array.isArray(answer) ? answer : [answer];
		const formattedAnswers: string[] = answerArray.map((singleAnswer) => singleAnswer.toString());

		return toAnswerRequestObject(`${cartGuid}`, questions, formattedAnswers);
	};

	const submitAcceptanceQuestion = async (answer: AnswerQuestionsRequest, toNextPage = nextPage): Promise<void> => {
		dispatch(setNavForward());
		await answerAcceptanceQuestion(answer).unwrap();
		const query: Record<string, string> = {
			cartGuid: cartGuid as string,
		};

		if (objectGuid) {
			query.objectGuid = objectGuid as string;
		}
		await push({
			pathname: `${toNextPage}`,
			query,
		});
	};

	return {
		isSuccess,
		mapToAnswer,
		submit: submitAcceptanceQuestion,
		response: answerAcceptanceQuestionResponse,
	};
};
