import { Trans } from 'next-i18next';
import { FC } from 'react';

import { CmsContainers } from '@monorepo/types';

import { Icon, IconButton } from '../../../Misc';
import { Text } from '../../../TextComponents';

export type InsuranceBlurbProps = {
	name: string;
	icon_name: string;
	guid: string;
	isOptional?: boolean;
	isFree?: boolean;
	isConsumer?: boolean;
	onClickTooltip?: (guid: string, container?: CmsContainers) => void;
};

export const InsuranceBlurb: FC<InsuranceBlurbProps> = ({
	name,
	icon_name,
	guid,
	isOptional,
	isFree,
	isConsumer,
	onClickTooltip,
}) => (
	<div className='flex items-center'>
		<div className='flex flex-grow items-center space-x-8'>
			<Icon name={icon_name} size='md' color={isOptional ? 'grayscale300' : 'primaryMain'} />
			<Text
				variant='body-s'
				weight={isOptional ? 'regular' : 'semibold'}
				color={isOptional ? 'grayscale300' : 'grayscale600'}>
				{isFree ? (
					<Trans
						i18nKey='components.InsuranceBlurb.freeOption'
						tOptions={{ option: name }}
						components={{
							free: <Text as='span' variant='body-s' weight='semibold' color='success300' />,
						}}
					/>
				) : (
					name
				)}
			</Text>
		</div>

		{onClickTooltip && !isConsumer && (
			<IconButton
				className='pointer-events-auto'
				iconName='info-circle'
				size='md'
				color='info300'
				onClick={() => onClickTooltip(guid, 'product_details')}
			/>
		)}
	</div>
);
