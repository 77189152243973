import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { BillingUnits, CmsContainers, CmsLink } from '@monorepo/types';

import { StandardButton } from '../../../DataEntry';
import { IconButton } from '../../../Misc';
import { EmphasizedPriceFormat, Text } from '../../../TextComponents';
import { InsuranceBlurb } from '../InsuranceBlurb/InsuranceBlurb';

export type MultipleProductCardFeature = {
	guid: string;
	name: string;
	icon: string;
};

export type MultipleProductCardProps = {
	guid: string;
	name: string;
	description?: string;
	minimum_price: number;
	isLoading?: boolean;
	isDisabled?: boolean;
	isConsumer?: boolean;
	billingUnit?: BillingUnits;
	requiredFeatures?: MultipleProductCardFeature[];
	optionalFeatures?: MultipleProductCardFeature[];
	compositionIncludes?: MultipleProductCardFeature[];
	className?: string;
	cmsOverrides?: {
		chooseProductButton?: CmsLink;
	};
	onChooseProduct: () => void;
	onMoreInfoClick: (guid: string, container?: CmsContainers) => void;
};

export const MultipleProductCard: FC<MultipleProductCardProps> = ({
	guid,
	name,
	description,
	minimum_price,
	requiredFeatures,
	optionalFeatures,
	compositionIncludes,
	isConsumer,
	billingUnit = 'monthly',
	isLoading,
	isDisabled,
	className,
	onChooseProduct,
	onMoreInfoClick,
	cmsOverrides,
}) => {
	const { t } = useTranslation(['common']);
	const hasCompositionIncludes = !!(compositionIncludes && compositionIncludes.length > 0);

	// Handle the click event for the insurance blurb
	const handleInsuranceBlurbClick = (guid: string) => {
		return onMoreInfoClick(guid, hasCompositionIncludes ? 'insured_activity_details' : 'product_details');
	};

	return (
		<div
			className={classNames('rounded-18 shadow-2 space-y-20 p-24 md:space-y-24', className)}
			data-testid={`multiple-product-card-${name}`}>
			<div className='space-y-16'>
				<div className='space-y-8'>
					<Text
						as='h3'
						variant='display-5'
						weight='semibold'
						color='grayscale600'
						className='flex-grow'
						data-testid={`multiple-product-card-${name}-name`}>
						{name}
					</Text>

					{description && (
						<Text as='span' variant='body-s' color='grayscale400' className='mt-4 flex-grow'>
							{description}
						</Text>
					)}

					<EmphasizedPriceFormat
						leadingText={t('common.from')}
						price={minimum_price}
						afterText={t(billingUnit === 'monthly' ? 'common.perMonthShort' : 'common.perYearShort')}
					/>
				</div>

				{onMoreInfoClick && (
					<IconButton
						onClick={() => onMoreInfoClick(guid, hasCompositionIncludes ? 'product_details' : 'bundle_details')}
						iconName='info-circle'
						color='info300'
						size='sm'
						suffix={
							isConsumer
								? t('components.MultipleProductCard.whatDoWeInsureWithItConsumer')
								: t('components.MultipleProductCard.whatDoWeInsureWithIt')
						}
					/>
				)}
			</div>

			<StandardButton
				variant='cta'
				iconRight='angle-right'
				label={
					cmsOverrides?.chooseProductButton?.content ||
					cmsOverrides?.chooseProductButton?.title ||
					t(
						hasCompositionIncludes
							? 'components.MultipleProductCard.pickInsurance'
							: 'components.MultipleProductCard.pickOptions'
					)
				}
				title={
					cmsOverrides?.chooseProductButton?.title ||
					cmsOverrides?.chooseProductButton?.content ||
					t(
						hasCompositionIncludes
							? 'components.MultipleProductCard.pickInsurance'
							: 'components.MultipleProductCard.pickOptions'
					)
				}
				className='w-full'
				onClick={onChooseProduct}
				disabled={isDisabled}
				isLoading={isLoading}
				data-testid={`multiple-product-card-${name}-cta`}
			/>
			<div className='space-y-8'>
				{requiredFeatures && requiredFeatures.length > 0 && (
					<>
						<Text variant='body-s' weight='regular' color='grayscale400'>
							{t('components.MultipleProductCard.packageContains')}
						</Text>

						<ul className='space-y-8' data-testid={`multiple-product-card-${name}-required-features`}>
							{requiredFeatures &&
								requiredFeatures.map((feature) => (
									<li key={`insurance-blurb-required-${guid}-${feature.guid}`}>
										<InsuranceBlurb
											onClickTooltip={handleInsuranceBlurbClick}
											name={feature.name}
											guid={feature.guid}
											icon_name={feature.icon}
											isConsumer={isConsumer}
										/>
									</li>
								))}
						</ul>
					</>
				)}

				{compositionIncludes && compositionIncludes.length > 0 && (
					<>
						<Text variant='body-s' weight='regular' color='grayscale400'>
							{t('components.MultipleProductCard.insuredFor')}
						</Text>

						<ul className='space-y-8' data-testid={`multiple-product-card-${name}-required-features`}>
							{compositionIncludes &&
								compositionIncludes.map((feature) => (
									<li key={`insurance-blurb-required-${guid}-${feature.guid}`}>
										<InsuranceBlurb
											onClickTooltip={handleInsuranceBlurbClick}
											name={feature.name}
											guid={feature.guid}
											icon_name={feature.icon}
											isConsumer={isConsumer}
										/>
									</li>
								))}
						</ul>
					</>
				)}
			</div>

			{optionalFeatures && optionalFeatures.length > 0 && (
				<div className='space-y-8'>
					<Text variant='body-s' weight='regular' color='grayscale400'>
						{t('components.MultipleProductCard.optional')}
					</Text>

					<ul className='space-y-8' data-testid={`multiple-product-card-${name}-optional-features`}>
						{optionalFeatures.map((feature) => (
							<li key={`insurance-blurb-optional-${guid}-${feature.guid}`}>
								<InsuranceBlurb
									onClickTooltip={handleInsuranceBlurbClick}
									name={feature.name}
									guid={feature.guid}
									icon_name={feature.icon}
									isOptional
								/>
							</li>
						))}
					</ul>
				</div>
			)}
		</div>
	);
};
