import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { HYDRATE } from 'next-redux-wrapper';

import { isBrowser, isTest } from '@monorepo/shared/lib/utils';

export const rootSuperAdminApi = createApi({
	reducerPath: 'rootSuperAdminApi',
	baseQuery: fetchBaseQuery({
		baseUrl: `${isTest || !isBrowser ? process.env.NEXT_PUBLIC_WEBAPP_URL : ''}/api/superadmin`,
		mode: 'cors',
		prepareHeaders: (headers, api) => {
			// @ts-ignore - Typescript type issue
			const token: string = api.getState().account?.idToken;
			token && headers.set(`Userauthorization`, `Bearer ${token}`);
			return headers;
		},
	}),
	tagTypes: ['CmsUsers', 'AiAssistants', 'AiFiles'],
	extractRehydrationInfo(action, { reducerPath }) {
		if (action.type === HYDRATE) {
			return action.payload[reducerPath];
		}
	},
	endpoints: () => ({}),
});
