import { Action, configureStore, createAction, ThunkAction } from '@reduxjs/toolkit';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';

import { isProduction } from '@monorepo/shared/lib/utils';

import { rootAuthApi } from '@auth/store';
import { rootCmsApi } from '@cms/store';
import {
	generalApi,
	getFirstContactApi,
	kvkApi,
	logMiddleware,
	modalMiddleware,
	modalSlice,
	navigationSlice,
	openAiApi,
	platformSlice,
	postalCodeApi,
	reviewLinkApi,
	rootApi,
	themeSlice,
	toastMiddleware,
	trustpilotApi,
} from '@common/store';
import { uboListenerMiddleware, uboSlice } from '@compliancy/store';
import { rootCompliancyApi } from '@compliancy/store/root';
import { consumersObjectsSlice, rootConsumerApi } from '@consumer/store';
import {
	classificationsSlice,
	companySlice,
	dataRootApi,
	premiumQuestionsSlice,
	productsSlice,
	progressSlice,
	shoppingCartSlice,
} from '@funnel/store';
import { cartSlice } from '@funnel/store/slices/cart';
import { accountSlice, claimSlice } from '@portal/store';
import { rootSuperAdminApi } from '@superadmin/store';
import { chatSlice } from '@support/store';

export const makeStore = () =>
	configureStore({
		reducer: {
			[getFirstContactApi.reducerPath]: getFirstContactApi.reducer,
			// Slices
			[accountSlice.name]: accountSlice.reducer,
			[cartSlice.name]: cartSlice.reducer,
			[chatSlice.name]: chatSlice.reducer,
			[claimSlice.name]: claimSlice.reducer,
			[companySlice.name]: companySlice.reducer,
			[classificationsSlice.name]: classificationsSlice.reducer,
			[modalSlice.name]: modalSlice.reducer,
			[navigationSlice.name]: navigationSlice.reducer,
			[themeSlice.name]: themeSlice.reducer,
			[platformSlice.name]: platformSlice.reducer,
			[premiumQuestionsSlice.name]: premiumQuestionsSlice.reducer,
			[productsSlice.name]: productsSlice.reducer,
			[progressSlice.name]: progressSlice.reducer,
			[shoppingCartSlice.name]: shoppingCartSlice.reducer,
			[uboSlice.name]: uboSlice.reducer,
			[consumersObjectsSlice.name]: consumersObjectsSlice.reducer,
			// APIs
			[rootApi.reducerPath]: rootApi.reducer,
			[dataRootApi.reducerPath]: dataRootApi.reducer,
			[generalApi.reducerPath]: generalApi.reducer,
			[kvkApi.reducerPath]: kvkApi.reducer,
			[trustpilotApi.reducerPath]: trustpilotApi.reducer,
			[openAiApi.reducerPath]: openAiApi.reducer,
			[reviewLinkApi.reducerPath]: reviewLinkApi.reducer,
			[postalCodeApi.reducerPath]: postalCodeApi.reducer,
			[rootAuthApi.reducerPath]: rootAuthApi.reducer,
			[rootCmsApi.reducerPath]: rootCmsApi.reducer,
			[rootSuperAdminApi.reducerPath]: rootSuperAdminApi.reducer,
			[rootCompliancyApi.reducerPath]: rootCompliancyApi.reducer,
			[rootConsumerApi.reducerPath]: rootConsumerApi.reducer,
		},
		// Adding the api middleware enables caching, invalidation, polling,
		// and other useful features of `rtk-query`.
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				immutableCheck: {
					warnAfter: 200,
				},
				serializableCheck: {
					warnAfter: 200,
				},
			}).concat([
				uboListenerMiddleware.middleware,
				getFirstContactApi.middleware,
				rootAuthApi.middleware,
				rootApi.middleware,
				dataRootApi.middleware,
				generalApi.middleware,
				kvkApi.middleware,
				trustpilotApi.middleware,
				openAiApi.middleware,
				reviewLinkApi.middleware,
				postalCodeApi.middleware,
				rootCmsApi.middleware,
				rootSuperAdminApi.middleware,
				rootCompliancyApi.middleware,
				rootConsumerApi.middleware,
				toastMiddleware,
				logMiddleware,
				modalMiddleware,
			]),
		devTools: !isProduction,
	});

export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>;
export const wrapper = createWrapper<AppStore>(makeStore, { debug: false });

export const hydrate = createAction<AppState>(HYDRATE);
