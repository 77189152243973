import cn from 'classnames';
import Image from 'next/image';
import React, { FC, PropsWithChildren, ReactNode } from 'react';

import { ThemeColors } from '@monorepo/types';

import { ButtonIcon, Icon } from '../../../Misc';
import { Text } from '../../../TextComponents';
import { TooltipIcon } from '../../Tooltips';
import { BaseCard } from '../BaseCard/BaseCard';

export type ChoiceCardProps = PropsWithChildren & {
	inputPosition?: 'left' | 'right';
	inputElement?: ReactNode;
	text?: string | ReactNode;
	imageSrc?: string;
	imageAlt?: string;
	checked?: boolean;
	iconName?: string;
	iconColor?: ThemeColors | 'inherit';
	tooltipId?: string;
	explicitSuccess?: boolean;
	onClick?: (e: Event) => void;
	className?: string;
	isLoading?: boolean;
	description?: string;
	disabled?: boolean;
	title?: string;
};

// Use this component within a <Form>
export const ChoiceCard: FC<ChoiceCardProps> = ({
	inputPosition,
	inputElement,
	text,
	checked = false,
	imageSrc,
	imageAlt = 'Image',
	iconName,
	iconColor,
	tooltipId,
	explicitSuccess = false,
	children,
	className = '',
	onClick,
	isLoading = false,
	disabled = false,
	description,
	title,
	...rest
}) => {
	return (
		<BaseCard
			role='button'
			title={title}
			tabIndex={0}
			onClick={onClick}
			isElevated={!checked && !disabled}
			className={cn(
				className,
				disabled && 'pointer-events-none cursor-not-allowed',
				explicitSuccess && 'border outline outline-1 outline-transparent',
				checked &&
					explicitSuccess &&
					'border-success300 hover:border-success400 active:border-success500 !outline-success300 hover:outline-success400 active:outline-success500',
				'md:pd-32 z-10 flex flex-col space-y-24 p-24'
			)}
			{...rest}>
			<div className='flex flex-row items-center space-x-24 md:space-x-20'>
				{/* Content */}
				<div className='flex flex-row items-center space-x-24 md:space-x-20'>
					{inputPosition === 'left' && inputElement && <div>{inputElement} </div>}
					{imageSrc && (
						<div className='relative block h-[45px] w-[105px]'>
							<Image src={imageSrc} alt={imageAlt} className='object-contain' fill />
						</div>
					)}
					{iconName && (
						<Icon name={iconName} color={disabled ? 'grayscale300' : iconColor} size='lg' className='leading-none' />
					)}
					{text && (
						<div className='flex flex-col'>
							<Text variant='body-l' weight='medium' color={disabled ? 'grayscale300' : 'grayscale600'}>
								{text}
							</Text>
							{checked && children && description && (
								<Text variant='body-s' weight='regular' color='grayscale400'>
									{description}
								</Text>
							)}
						</div>
					)}
				</div>
				{/* Control */}
				<div className='!ml-auto flex flex-row items-center space-x-24'>
					{tooltipId && (
						<div>
							<TooltipIcon id={tooltipId} />
						</div>
					)}
					{inputPosition === 'right' && inputElement && <div>{inputElement}</div>}
					{(!inputElement || !inputPosition) && (
						<ButtonIcon
							iconColor={disabled ? 'grayscale300' : 'info300'}
							isLoading={isLoading}
							className='!ml-auto transition-transform group-hover:translate-x-4'
							icon='angle-right-b'
							iconPosition='right'
							mode='dark'
						/>
					)}
				</div>
			</div>
			{checked && children && children}
		</BaseCard>
	);
};
