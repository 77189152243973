import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { DetailsCard, DetailsLine } from '@monorepo/components/DataDisplay';
import { transformToCurrency } from '@monorepo/shared/lib/utils';
import { CalculationPrecondition, CalculationPreconditionReference } from '@monorepo/types';

type PreconditionBoxProps = {
	preconditions: CalculationPrecondition[];
	isLoading?: boolean;
	onEdit: () => void;
};

export const PreconditionBox: FC<PreconditionBoxProps> = ({ preconditions, onEdit, isLoading }) => {
	const { t } = useTranslation(['common']);

	const copy: Record<CalculationPreconditionReference, { title: string; icon: string }> = {
		default: {
			title: t('components.preconditionsBox.preconditions.default'),
			icon: 'question',
		},
		'gross-wage': {
			title: t('components.preconditionsBox.preconditions.grossWage'),
			icon: 'euro',
		},
		'rebuild-value': {
			title: t('components.preconditionsBox.preconditions.rebuildValue'),
			icon: 'constructor',
		},
		theft: {
			title: t('components.preconditionsBox.preconditions.theft'),
			icon: 'ninja',
		},
		employees: {
			title: t('components.preconditionsBox.preconditions.employees'),
			icon: 'users-alt',
		},
		owners: {
			title: t('components.preconditionsBox.preconditions.owners'),
			icon: 'user',
		},
		AANTTMW: {
			title: t('components.preconditionsBox.preconditions.aanttmw'),
			icon: 'clock',
		},
	};

	return (
		<DetailsCard title={t('common.premiumQuestions')} onEdit={onEdit} isDisabled={isLoading} isLoading={isLoading}>
			{preconditions.map((precondition) => {
				const calculationPreconditionKey = copy.hasOwnProperty(precondition.reference)
					? precondition.reference
					: 'default';

				return (
					<DetailsLine
						key={precondition.guid}
						icon={copy[calculationPreconditionKey].icon}
						isLoading={isLoading}
						title={copy[calculationPreconditionKey].title}
						content={
							precondition?.reference === 'rebuild-value' ||
							precondition?.reference === 'gross-wage' ||
							precondition?.reference === 'theft'
								? transformToCurrency(precondition.answer)
								: precondition?.answer
						}
					/>
				);
			})}
		</DetailsCard>
	);
};
